<template>
    <Header />

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" role="main"  style="position: relative; top: 120px; margin-bottom: 120px;">
    <div class="bg-img-start" style="background-image: url(assets/svg/components/card-11.svg);">
     <div :style="backgroundStyle" style="position: relative; width: 100%; height: 250px; 
     ">
        <div class="container content-space-t-3" >
        
          <div class="w-lg-75 text-center mx-lg-auto">
            <!-- Heading -->
            <div class="mb-5 mb-md-10">
              <h1 class="display-6 text-gray glowing-text" style="font-color: #fff !important">Contactez-nous</h1>
            </div>
            <!-- End Heading -->
          </div>
        </div>
      </div>
    <!-- End Gallery -->
    <div class="container" style="margin-top: 20px">
    
      <nav class=" p-3" aria-label="breadcrumb" style="background: #133985; margin-top: 20px">
          <ol class="breadcrumb breadcrumb-light mb-0">
            <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/">Accueil  </router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Contactez-nous  </li>
          </ol>
        </nav>
      <div class="row" style="margin-top: 20px">
        <div class="col-lg-6 mb-9 mb-lg-0">
          <!-- Heading -->
          <div class="mb-5">
            <!-- <h1>Get in touch</h1>
            <p>We'd love to talk about how we can help you.</p> -->
          </div>
          <!-- End Heading -->

          <!-- Leaflet -->
          <div class="overflow-hidden">
            <div id="map" class="leaflet mb-5"></div>
            <!-- <div id="map" class="leaflet mb-5" data-hs-leaflet-options='{
                 "map": {
                   "scrollWheelZoom": false,
                   "coords": [37.4040344, -122.0289704]
                 },
                 "marker": [
                   {
                     "coords": [37.4040344, -122.0289704],
                     "icon": {
                       "iconUrl": "./assets/svg/components/map-pin.svg",
                       "iconSize": [50, 45]
                     },
                     "popup": {
                       "text": "153 Williamson Plaza, Maggieberg"
                     }
                   }
                 ]
                }'></div> -->
          </div>
          <!-- End Leaflet -->

          <div class="row">
            <div class="col-sm-6">
              <h5 class="mb-1">Appelez nous au:</h5>
              <p>+242 04 78 08 34 31 </p>
            </div>
            <!-- End Col -->

            <div class="col-sm-6">
              <h5 class="mb-1">Ecrivez nous par mail:</h5>
              <p>contact@sedic.cg</p>
            </div>
            <!-- End Col -->

            <div class="col-sm-6">
              <h5 class="mb-1">Adresse:</h5>
              <p>Avenue de l'Intendance M'PILA Brazzaville - CONGO</p>
            </div>
            <!-- End Col -->
          </div>
          <!-- End Row -->
        </div>
        <!-- End Col -->

        <div class="col-lg-6">
          <div class="ps-lg-5">
            <!-- Card -->
            <div class="card">
              <div class="card-header border-bottom text-center">
                <h3 class="card-header-title">Laissez nous un message</h3>
              </div>

              <div class="card-body">
                <!-- Form -->
                <form>
                  <div class="row gx-3">
                    <div class="col-sm-6">
                      <!-- Form -->
                      <div class="mb-3">
                        <label class="form-label" for="hireUsFormFirstName">Prénom</label>
                        <input type="text" class="form-control form-control-lg" name="hireUsFormNameFirstName" id="hireUsFormFirstName" placeholder="Votre prénom" aria-label="First name">
                      </div>
                      <!-- End Form -->
                    </div>
                    <!-- End Col -->

                    <div class="col-sm-6">
                      <!-- Form -->
                      <div class="mb-3">
                        <label class="form-label" for="hireUsFormLasttName">Nom</label>
                        <input type="text" class="form-control form-control-lg" name="hireUsFormNameLastName" id="hireUsFormLasttName" placeholder="Votre nom" aria-label="Last name">
                      </div>
                      <!-- End Form -->
                    </div>
                    <!-- End Col -->
                  </div>
                  <!-- End Row -->

                  <div class="row gx-3">
                    <div class="col-sm-6">
                      <!-- Form -->
                      <div class="mb-3">
                        <label class="form-label" for="hireUsFormWorkEmail">Email</label>
                        <input type="email" class="form-control form-control-lg" name="hireUsFormNameWorkEmail" id="hireUsFormWorkEmail" placeholder="email@site.com" aria-label="email@site.com">
                      </div>
                      <!-- End Form -->
                    </div>
                    <!-- End Col -->

                    <div class="col-sm-6">
                      <!-- Form -->
                      <div class="mb-3">
                        <label class="form-label" for="hireUsFormPhone">Téléphone <span class="form-label-secondary">(Optionnel)</span></label>
                        <input type="text" class="form-control form-control-lg" name="hireUsFormNamePhone" id="hireUsFormPhone" placeholder="+(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx">
                      </div>
                      <!-- End Form -->
                    </div>
                    <div class="col-sm-12">
                      <!-- Form -->
                      <div class="mb-3">
                        <label class="form-label" for="hireUsFormPhone">Sujet <span class="form-label-secondary"></span></label>
                        <select class="form-select form-select-lg" id="locationsJobCareers" aria-label="Choisissez un sujet">
                          <option selected>Les Tours Jumelles</option>
                          <option>Le Musée</option>
                          <option>Le Brazza Mall</option>
                          <option>Les logements de M'Pila</option>
                          <option>Le Grand Hôtel de Kintélé</option>
                          <option>Autres</option>
                        </select>
                      </div>
                      <!-- End Form -->
                    </div>
                    <!-- End Col -->
                  </div>
                  <!-- End Row -->

                  <!-- Form -->
                  <div class="mb-3">
                    <label class="form-label" for="hireUsFormDetails">Message</label>
                    <textarea class="form-control form-control-lg" name="hireUsFormNameDetails" id="hireUsFormDetails" placeholder="Ecrivez votre message ..." aria-label="Tell us about your ..." rows="4"></textarea>
                  </div>
                  <!-- End Form -->

                  <div class="d-grid">
                    <button type="submit" class="btn btn-primary btn-lg">Envoyer</button>
                  </div>

                  <div class="text-center">
                    <p class="form-text">Nous vous recontacterons très prochainement.</p>
                  </div>
                </form>
                <!-- End Form -->
              </div>
            </div>
            <!-- End Card -->
          </div><br>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->
    </div>

          <!-- End Info -->
    <!-- End Hero -->
    </div>
  </main>
    
    <Footer />
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'
import L from 'leaflet'

export default {
  name: 'contact-page',
  data() {
    return {
      isVisible: false, // Initialisez à false pour masquer l'image initialement
      isExiting: false,
      backgroundImages: [
        'url(assets/img/contact/contact1.jpg)',
        'url(assets/img/contact/contact2.jpg)', // Remplacez ceci par les URL de vos images
        // 'url(assets/img/4.jpg)', // Ajoutez autant d'images que nécessaire
      ],
      currentIndex: 0,
      animationDuration: 4000, // Durée de l'animation en millisecondes
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: this.backgroundImages[this.currentIndex],
        animation: `scrollBackground ${this.animationDuration / 400}s linear infinite`,
        backgroundSize: 'cover', // Mettre l'image en mode "cover"
      };
    },
  },
  methods: {
    startBackgroundScroll() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
      }, this.animationDuration);
    },
    changeBackgroundImage() {
      this.fadingOut = true;
      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
        this.fadingOut = false;
      }, 1000);
    },
  checkIfVisible() {
    const element = document.querySelector('.mg');
    if (!element) return;

    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Vérifiez si l'élément est visible à l'écran
    if (rect.top <= windowHeight && rect.bottom >= 100) {
      this.isVisible = true;
    } else {
      this.isVisible = false; // Assurez-vous de définir isVisible à false lorsque l'élément n'est pas visible

      // Si l'utilisateur quitte le pied de page en remontant, appliquez l'animation de sortie vers le haut
      if (window.scrollY < rect.top) {
        this.isExiting = true;
      } else {
        this.isExiting = false;
      }
    }
  },
  },
  mounted() {
    // Créez une instance de carte Leaflet et spécifiez les coordonnées et le niveau de zoom initiaux.
    const map = L.map('map').setView([-4.24268, 15.29441], 17);

    // Ajoutez une couche de carte (par exemple, OpenStreetMap) à votre carte.
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '', // Supprime l'attribution aux contributeurs d'OpenStreetMap
    }).addTo(map);

    // Ajouter un marqueur personnalisé
    const customIcon = L.icon({
      iconUrl: '/assets/img/Map-Marker-Transparent-Image.png', // Chemin vers votre icône
      iconSize: [32, 32], // Taille de l'icône (largeur x hauteur)
      iconAnchor: [16, 32], // Point d'ancrage de l'icône (centre inférieur)
    });
    // Ajouter un marqueur à Mpila
    const marker = L.marker([-4.24268, 15.29441], { icon: customIcon }, 17).addTo(map);
  // Vous pouvez personnaliser le marqueur en ajoutant une icône, un popup, etc.
    marker.bindPopup("<b>SEDIC</b>").openPopup();

    this.startBackgroundScroll();
    // Écoutez l'événement de défilement pour vérifier si l'élément est visible
    window.addEventListener('scroll', this.checkIfVisible);
    // Vérifiez une première fois au chargement de la page
    this.checkIfVisible();
  },
  beforeUnmount() {
    // Assurez-vous de supprimer l'écouteur d'événement lorsque le composant est détruit
    window.removeEventListener('scroll', this.checkIfVisible);
  },
  components: {
    Header,
    Footer
  }
};
</script>

<style>
/* Styles spécifiques à la page si nécessaire */
.glowing-text {
  font-size: 36px;
  /* color: #fff; Couleur du texte */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
  position: relative;
  overflow: hidden;
  /* width: 300px; /* Ajustez la largeur à votre besoin */
  /* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
  width: 100%;
  height: 100%;
  transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
  transform: scale(0.9); /* Augmente la taille de l'image à 120% */
  opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 100%;
  }
}

.background-image {
  background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation de sortie */
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
  animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
  animation: slideInLeft 2s ease both;
}

.slide-in-right {
  animation: slideInRight 2s ease both;
}

.slide-out-left {
  animation: slideOutLeft 2s ease both;
}

.slide-out-right {
  animation: slideOutRight 2s ease both;
}
.ma-div {
  /* Ajoutez votre contenu CSS existant pour la div ici */
  width: 200px;
  height: 200px;
  background-color: transparent;
  position: relative;
  border-radius: 4px; 
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
  height: 100%;
  background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
  height: 100%;
  background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}
</style>
