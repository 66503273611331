import { createRouter, createWebHistory } from 'vue-router';
import Accueil from '../views/AccueilLayout.vue';
import Mission from '../views/Mission-view.vue';
import Valeur from '../views/Valeur-view.vue';
import Gouvernance from '../views/Gouvernance-view.vue';
import Nous from '../views/Nous-view.vue';
import Contact from '../views/Contact-view.vue';
import Projet from '../views/Projet-view.vue';
import Actualite from '../views/Actualite-view.vue';
import Tour from '../views/Tour-view.vue';
import Logement from '../views/Logement-view.vue';
import Brazza from '../views/Brazza-view.vue';
import Grandhotel from '../views/Grandhotel-view.vue';
import Musee from '../views/Musee-view.vue';
import Actu1 from '../views/Actu-view1.vue';
import Actu2 from '../views/Actu-view2.vue';
import Actu3 from '../views/Actu-view3.vue';
import Actu4 from '../views/Actu-view4.vue';
import Focus1 from '../views/Focus-view1.vue';
import Focus2 from '../views/Focus-view2.vue';
import Focus3 from '../views/Focus-view3.vue';
// import Footer from '../components/FooterLayout.vue'; // Importez le composant Footer

const routes = [
  { 
    name: 'Accueil',
    path: '/', 
    component: Accueil,
    meta: {
      title: 'Accueil'
    } 
  },
  { 
    name: 'Tour',
    path: '/tourjumelle',
    component: Tour ,
    meta: {
      title: 'Les Tours Jumelles' 
    }
  },
  { 
    name: 'Musee',
    path: '/musee',
    component: Musee ,
    meta: {
      title: 'Le Musée' 
    }
  },
  { 
    name: 'Actu1',
    path: '/actu1',
    component: Actu1 ,
    meta: {
      title: 'Actualité' 
    }
  },
  { 
    name: 'Actu2',
    path: '/actu2',
    component: Actu2 ,
    meta: {
      title: 'Actualité' 
    }
  },
  { 
    name: 'Actu3',
    path: '/actu3',
    component: Actu3 ,
    meta: {
      title: 'Actualité' 
    }
  },
  { 
    name: 'Actu4',
    path: '/actu4',
    component: Actu4 ,
    meta: {
      title: 'Actualité' 
    }
  },
  { 
    name: 'Focus1',
    path: '/focus1',
    component: Focus1 ,
    meta: {
      title: 'Focus' 
    }
  },
  { 
    name: 'Focus2',
    path: '/focus2',
    component: Focus2 ,
    meta: {
      title: 'Focus' 
    }
  },
  { 
    name: 'Focus3',
    path: '/focus3',
    component: Focus3 ,
    meta: {
      title: 'Focus' 
    }
  },
  { 
    name: 'Brazza',
    path: '/brazzamall',
    component: Brazza ,
    meta: {
      title: 'Le Brazza Mall' 
    }
  },
  { 
    name: 'Logement',
    path: '/logementmpila',
    component: Logement ,
    meta: {
      title: "Les logements de M'PILA" 
    }
  },
  { 
    name: 'Grand',
    path: '/grandhotel',
    component: Grandhotel ,
    meta: {
      title: 'Le Grand Hôtel de kintélé' 
    }
  },
  { 
    name: 'Missions',
    path: '/mission',
    component: Mission ,
    meta: {
      title: 'Missions' 
    }
  },
  { 
    name: 'Valeurs',
    path: '/valeur', 
    component: Valeur,
    meta: {
      title: 'Valeurs' 
    } 
  },
  { 
    name: 'Gouvernance',
    path: '/gouvernance', 
    component: Gouvernance ,
    meta: {
      title: 'Gouvernance'
    }
  },
  { 
    name: 'Qui sommes Nous',
    path: '/quisommesnous', 
    component: Nous ,
    meta: {
      title: 'Qui sommes nous'
    }
  },
  { 
    name: 'Projets',
    path: '/projet', 
    component: Projet,
    meta: {
      title: 'Réalisations'
    } 
  },
  { 
    name: 'Actualites',
    path: '/actualite', 
    component: Actualite,
    meta: {
      title: 'Actualités'
    } 
  },
  { 
    name: 'Contact',
    path: '/contact', 
    component: Contact,
    meta: {
      title: 'Contactez-nous'
    } 
  }
  // Ajoutez d'autres routes personnalisées ici
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Titre par défaut'
  next()
});
// Ajoutez le hook beforeEach pour gérer le preloader



export default router;
