<template>
    <Header />

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" role="main"  style="position: relative; top: 120px; margin-bottom: 120px">
    <div class="bg-img-start" style="background-image: url(assets/svg/components/card-11.svg);">
     <div :style="backgroundStyle" style="position: relative; width: 100%; height: 250px;
      ">
        <div class="container content-space-t-3" >
        
          <div class="w-lg-75 text-center mx-lg-auto">
            <!-- Heading -->
            <div class="mb-5 mb-md-10">
              <h1 class="display-6 text-gray glowing-text" style="font-color: #fff !important">Actualités</h1>
            </div>
            <!-- End Heading -->
          </div>
        </div>
      </div>
      
    <!-- Card Grid -->
    <div class="container" style="margin-top: 20px">
    
      <nav class=" p-3" aria-label="breadcrumb" style="background: #133985; margin-top: 20px">
          <ol class="breadcrumb breadcrumb-light mb-0">
            <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/">Accueil  </router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Actualités  </li>
          </ol>
        </nav>
      <div class="row justify-content-lg-between" style="margin-top: 20px">
        <div class="col-lg-12">

          <!-- Sticky Block Start Point -->
          <div id="stickyBlockStartPoint"></div>

          <div class="js-sticky-block" data-hs-sticky-block-options='{
                 "parentSelector": "#stickyBlockStartPoint",
                 "targetSelector": "#header",
                 "breakpoint": "md",
                 "startPoint": "#stickyBlockStartPoint",
                 "endPoint": "#stickyBlockEndPoint",
                 "stickyOffsetTop": 80
               }'>

            <div class="d-md-flex align-items-md-center text-center text-md-start">
              <span class="d-block me-md-3 mb-2 mb-md-1">Catégories:</span>
              <a class="btn btn-soft-secondary btn-xs rounded-pill mb-1" @click="filterNews('Annonce')" href="#">Annonce</a>&nbsp;
              <a class="btn btn-soft-secondary btn-xs rounded-pill mb-1" @click="filterNews('Partenariat')" href="#">Partenariat</a>&nbsp;
              <a class="btn btn-soft-secondary btn-xs rounded-pill mb-1" @click="filterNews('Evenement')" href="#">Evénement</a>&nbsp;
              <a class="btn btn-soft-secondary btn-xs rounded-pill mb-1" @click="filterNews('All')" href="#">Général</a>&nbsp;
            </div>
          </div>
        </div> <br> <br>
        <!-- End Col -->
        
      <div class="row mb-7">
        <div v-for="newsItem in filteredNews" :key="newsItem.id" class="col-sm-6 col-lg-4 mb-4">
          <!-- Card -->
          <div class="card h-100">
            <div class="shape-container">
              <img class="card-img-top" :src="newsItem.image" alt="Image Description">

              <!-- Shape -->
              <div class="shape shape-bottom zi-1" style="margin-bottom: -.25rem">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                </svg>
              </div>
              <!-- End Shape -->
            </div>

            <!-- Card Body -->
            <div class="card-body">
              <div class="mb-5">
                <span class="badge bg-primary">{{ newsItem.category }}</span>
              </div>
              <h3 class="card-title">
                <router-link class="text-dark" :to="newsItem.link">{{ newsItem.title }}</router-link>
              </h3>

            </div>
            <!-- End Card Body -->

            <!-- Card Footer -->
            <div class="card-footer">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 avatar-group avatar-group-xs">
                 <a class="avatar avatar-xs avatar-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Emily Milda">
                    <img class="avatar-img" src="assets/img/160x160/img4.jpg" alt="Image Description">
                  </a>
                </div>

                <div class="flex-grow-1">
                  <div class="d-flex justify-content-end">
                    <p class="card-text">{{ newsItem.date }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Card Footer -->
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
      </div>

      </div>
      <!-- End Row -->
    </div>
    <!-- End Card Grid -->
    </div>
  </main>
    
    <Footer />
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'

export default {
  name: 'actualite-page',
  data() {
    return {
      isVisible: false, // Initialisez à false pour masquer l'image initialement
      isExiting: false,
      backgroundImages: [
        // 'url(assets/img/banactu/banactu1.jpg)',
        'url(assets/img/slider/banni3.jpg)',
        'url(assets/img/tour/tourju.jpg)',
        'url(assets/img/banactu/banactu2.jpg)', 
        // 'url(assets/img/banactu/banactu3.jpg)',
      ],
      currentIndex: 0,
      animationDuration: 4000, // Durée de l'animation en millisecondes
      news: [
        { id: 1, title: "L'inauguration imminente des tours jumelles", date: 'Septembre 07, 2023', 
        image: 'assets/img/1920x1080/actu4.jpg', content: 'Contenu de l\'annonce 1', category: 'Evenement', link: 'actu1' },
        { id: 2, title: "L'inauguration de Brazza Mal très bientôt", date: 'Septembre 07, 2023', 
        image: `assets/img/1920x1080/actu1.jpg`,  content: 'Contenu de l\'annonce 2', category: 'Evenement', link: 'actu2' },
        { id: 3, title: "Sommet des 3 bassins à Brazzaville du 26 au 28 Octobre 2023", date: 'Septembre 07, 2023', 
        image: `assets/img/1920x1080/actu2.jpg`,  content: 'Contenu de l\'événement 1', category: 'Evenement', link: 'actu3' },
        { id: 4, title: "Une offre spéciale à partir de septembre sur le programme immobilier de mpila", date: 'Septembre 07, 2023',
        image: `assets/img/1920x1080/actu3.jpg`,  content: 'Contenu de l\'annonce 3', category: 'Annonce', link: 'actu4' },
         // Ajoutez d'autres actualités avec leurs catégories
      ],
      selectedCategory: 'All'
    };
  },
  computed: {
    filteredNews() {
      if (this.selectedCategory === 'All') {
        return this.news;
      } else {
        return this.news.filter(item => item.category === this.selectedCategory);
      }
    },
    backgroundStyle() {
      return {
        backgroundImage: this.backgroundImages[this.currentIndex],
        animation: `scrollBackground ${this.animationDuration / 400}s linear infinite`,
        backgroundSize: 'cover', // Mettre l'image en mode "cover"
      };
    },
  },
  methods: {
    filterNews(category) {
      this.selectedCategory = category;
    },
    startBackgroundScroll() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
      }, this.animationDuration);
    },
    changeBackgroundImage() {
      this.fadingOut = true;
      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
        this.fadingOut = false;
      }, 1000);
    },
  checkIfVisible() {
    const element = document.querySelector('.mg');
    if (!element) return;

    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Vérifiez si l'élément est visible à l'écran
    if (rect.top <= windowHeight && rect.bottom >= 100) {
      this.isVisible = true;
    } else {
      this.isVisible = false; // Assurez-vous de définir isVisible à false lorsque l'élément n'est pas visible

      // Si l'utilisateur quitte le pied de page en remontant, appliquez l'animation de sortie vers le haut
      if (window.scrollY < rect.top) {
        this.isExiting = true;
      } else {
        this.isExiting = false;
      }
    }
  },
  },
  mounted() {
    this.startBackgroundScroll();
    // Écoutez l'événement de défilement pour vérifier si l'élément est visible
    window.addEventListener('scroll', this.checkIfVisible);
    // Vérifiez une première fois au chargement de la page
    this.checkIfVisible();
  },
  beforeUnmount() {
    // Assurez-vous de supprimer l'écouteur d'événement lorsque le composant est détruit
    window.removeEventListener('scroll', this.checkIfVisible);
  },
  components: {
    Header,
    Footer
  }
};
</script>

<style>
/* Styles spécifiques à la page si nécessaire */
.glowing-text {
  font-size: 36px;
  /* color: #fff; Couleur du texte */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
  position: relative;
  overflow: hidden;
  /* width: 300px; /* Ajustez la largeur à votre besoin */
  /* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
  width: 100%;
  height: 100%;
  transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
  transform: scale(0.9); /* Augmente la taille de l'image à 120% */
  opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 100%;
  }
}

.background-image {
  background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation de sortie */
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
  animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
  animation: slideInLeft 2s ease both;
}

.slide-in-right {
  animation: slideInRight 2s ease both;
}

.slide-out-left {
  animation: slideOutLeft 2s ease both;
}

.slide-out-right {
  animation: slideOutRight 2s ease both;
}
.ma-div {
  /* Ajoutez votre contenu CSS existant pour la div ici */
  width: 200px;
  height: 200px;
  background-color: transparent;
  position: relative;
  border-radius: 4px; 
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
  height: 100%;
  background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
  height: 100%;
  background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}
</style>
