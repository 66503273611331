<template>
    <Header />

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" role="main" style="position: relative; top: 120px;">
    <!-- Hero -->
    <div class="bg-img-start" style="background-images: url(assets/svg/components/card-11.svg);">
     <div :style="backgroundStyle" style="position: relative; width: 100%; height: 250px;">
        <div class="container content-space-t-3" >
        
          <div class="w-lg-75 text-center mx-lg-auto">
            <!-- Heading -->
            <div class="mb-5 mb-md-10">
              <h1 class="display-6 text-gray glowing-text" style="font-color: #fff !important">Les Tours jumelles</h1>
            </div>
            <!-- End Heading -->
          </div>
        </div>
      </div>
    <!-- End Gallery -->

              <!-- End Breadcrumb -->
          <!-- Info -->
          <div class="container">
            
            <nav class=" p-3" aria-label="breadcrumb" style="background: #133985; margin-top: 20px">
                <ol class="breadcrumb breadcrumb-light mb-0">
                  <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/">Accueil  </router-link></li>
                  <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/projet">Notre portefeuille  </router-link></li>
                  <li class="breadcrumb-item active" aria-current="page">Les tours jumelles</li>
                </ol>
              </nav>
            <div class="row justify-content-lg-between">
              <!-- <div class="col-lg-2 mb-5 mb-lg-0">
                <h2>Historique</h2>
              </div> -->
              <!-- End Col -->
              <!-- <div class="col-md-12 card-transition h-100  ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
                <strong> <span style="color: #133985">PRESENTATION</span>&nbsp;
                  <span style="color: #000">DES TOURS JUMELLES</span></strong>
              </div>

              <div  class=" col-lg-12" style="padding-top: 10px">
                <p style="text-align: justify;">Texte de présentation.
                </p>
              </div> -->
              <!-- End Col -->
            </div> 
            <div class="row justify-content-lg-center content-space-2">
              <div class="col-lg-3 ">
                <h2>Brazza Trade Center</h2>
              </div>
              <!-- End Col -->

              <div class="col-lg-8 offset-lg-1">
                <p style="text-align: justify">La République du Congo entend optimiser, valoriser et pérenniser ses infrastructures
                  qui doivent être des leviers de croissance et de développement.
                  Pour ce faire, le gouvernement à travers le Ministère de l’Aménagement du Territoire,
                  des Infrastructures et de l’Entretien Routier s’est doté d’un véhicule d’optimisation de
                  son patrimoine par la création en avril 2022 d’une Société d’Exploitation et de
                  Développement des Infrastructures ( SEDIC ). L’Etat a transféré à la SEDIC divers actifs
                  infrastructurels , principalement dans les domaines de la distribution ( Brazza Mall ),
                  l’hôtellerie (le Complexe Hôtelier et Centre de Conférence de Kintélé, les Tours
                  Jumelles de Mpila ) et de l’immobilier ( le parc de logements de Mpila ).</p>
              </div>
              <!-- End Col -->
            </div>
            <!-- End Row -->
            <div class="row gx-3">
              <div class="col mb-3">
                <div class="bg-img-start" style="background-image: url(assets/img/trade/trade1.jpg); height: 15rem;"></div>
              </div>
              <!-- End Col -->

              <div class="col-3 d-none d-md-block mb-3">
                <div class="bg-img-start" style="background-image: url(assets/img/trade/trade7.jpg); height: 15rem;"></div>
              </div>
              <!-- End Col -->

              <div class="col mb-3">
                <div class="bg-img-start" style="background-image: url(assets/img/trade/trade3.jpg); height: 15rem;"></div>
              </div>
              <!-- End Col -->

              <div class="w-100"></div>

              <div class="col mb-3 mb-md-0">
                <div class="bg-img-start" style="background-image: url(assets/img/trade/trade4.jpg); height: 15rem;"></div>
              </div>
              <!-- End Col -->

              <div class="col-4 d-none d-md-block mb-3 mb-md-0">
                <div class="bg-img-start" style="background-image: url(assets/img/trade/trade5.jpg); height: 15rem;"></div>
              </div>
              <!-- End Col -->

              <div class="col">
                <div class="bg-img-start" style="background-image: url(assets/img/trade/trade6.jpg); height: 15rem;"></div>
              </div>
              <!-- End Col -->
            </div>
            <!-- End Row --> <br>
<div class="position-relative">
  <div class="bg-default bg-img-start" >
    <div class="container">
      <!-- Heading -->
      <div class="w-lg-50 text-center mx-lg-auto mb-7">
        <!-- <span class="text-cap text-white-70">Contact us</span> --><br><br>
        <!-- <h2 class="text-black lh-base">HOSPITALITY TOWER <span class="text-warning">(Hôtel 5 étoiles)</span></h2> -->
      </div> 
      <!-- End Heading -->
      <!-- Lists -->
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-4 mb-5 mb-md-0">

            <div class="mb-4">
              <h4>HOSPITALITY TOWER <span class="text-warning">(Hôtel 5 étoiles)</span></h4>
            </div>

            <!-- List -->
            <ul class="list-unstyled list-pointer" style="text-align: justify">
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Une large gamme de suites , chambres et appartements avec vue panoramique
                sur la capitale, Brazzaville : <br>
                40 suites, 128 chambres standards et 8 suites présidentielles ;<br>
                4 chambres pour la garde présidentielle ; 48 appartements de 3 et 4 chambres;
                </a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">2 restaurants gastronomiques dont un au sommet de la Tour, rotatif et panoramique ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Espace de détente de relaxation (SPA, salon de coiffure et de beauté, Fitness Room) ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Galeries marchandes ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Salle de jeux ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Night-Club ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Salle de Banquets et salle de conférences.</a>
              </li>
            </ul>
            <!-- End List -->

          </div>
          <!-- End Col -->

          <div class="col-sm-6 col-md-4 mb-5 mb-md-0">
            <div class="mb-4">
              <h4>BUSINESS TOWER</h4>
            </div>

            <!-- List -->
            <ul class="list-unstyled list-pointer" style="text-align: justify">
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Espace de bureaux équipés à louer ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Domiciliation d’entreprises ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Espaces de coworking ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Infrastructures de télécommunications et de communication (connexion internet très haut débit, salle de réunion, mise à disposition du matériel pour les visioconférences) ;</a>
              </li>
            </ul>
            <!-- End List -->

          </div>
          <!-- End Col -->

          <div class="col-sm-6 col-md-4">
            <div class="mb-4">
              <h4>AUTRES SERVICES</h4>
            </div>

            <!-- List -->
            <ul class="list-unstyled list-pointer">
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">Lobby accueil réception renseignements ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">LED d’informations ;</a>
              </li>
              <li class="list-pointer-item">
                <a class="link link-secondary" href="#">WiFi Public</a>
              </li>
            </ul>
            <!-- End List -->

          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- End Lists -->
    </div>
  </div>

</div>
<!-- End Contacts -->       

              
            <!-- End Row -->
          </div>
    <!-- Appliquez la classe d'animation de sortie vers le haut lorsque isExiting est true -->
        <p style="margin-top: 150px"></p>
          <!-- End Info -->
    <!-- End Hero -->
    </div>
  </main>
    
    <Footer />
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'

export default {
  name: 'nous-page',
  data() {
    return {
      isVisible: false, // Initialisez à false pour masquer l'image initialement
      isExiting: false,
      backgroundImages: [
        'url(assets/img/tour/1000015171web.jpg)',
        'url(assets/img/tour/1000015294web.jpg)', // Remplacez ceci par les URL de vos images
        'url(assets/img/tour/1000015534web.jpg)', // Ajoutez autant d'images que nécessaire
        'url(assets/img/tour/tour4.jpg)', // Ajoutez autant d'images que nécessaire
        'url(assets/img/tour/tour5.jpg)', // Ajoutez autant d'images que nécessaire
      ],
      currentIndex: 0,
      animationDuration: 4000, // Durée de l'animation en millisecondes
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: this.backgroundImages[this.currentIndex],
        animation: `scrollBackground ${this.animationDuration / 400}s linear infinite`,
        backgroundSize: 'cover', // Mettre l'image en mode "cover"
      };
    },
  },
  methods: {
    startBackgroundScroll() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
      }, this.animationDuration);
    },
    changeBackgroundImage() {
      this.fadingOut = true;
      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
        this.fadingOut = false;
      }, 1000);
    },
  checkIfVisible() {
    const element = document.querySelector('.mg');
    if (!element) return;

    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Vérifiez si l'élément est visible à l'écran
    if (rect.top <= windowHeight && rect.bottom >= 1600) {
      this.isVisible = true;
    } else {
      this.isVisible = false; // Assurez-vous de définir isVisible à false lorsque l'élément n'est pas visible

      // Si l'utilisateur quitte le pied de page en remontant, appliquez l'animation de sortie vers le haut
      if (window.scrollY < rect.top) {
        this.isExiting = true;
      } else {
        this.isExiting = false;
      }
    }
  },
  },
  mounted() {
    this.startBackgroundScroll();
    // Écoutez l'événement de défilement pour vérifier si l'élément est visible
    window.addEventListener('scroll', this.checkIfVisible);
    // Vérifiez une première fois au chargement de la page
    this.checkIfVisible();
  },
  beforeUnmount() {
    // Assurez-vous de supprimer l'écouteur d'événement lorsque le composant est détruit
    window.removeEventListener('scroll', this.checkIfVisible);
  },
  components: {
    Header,
    Footer
  }
    
}
</script>

<style>
/* Styles spécifiques à la page si nécessaire */
.glowing-text {
  font-size: 36px;
  /* color: #fff; Couleur du texte */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
  position: relative;
  overflow: hidden;
  /* width: 300px; /* Ajustez la largeur à votre besoin */
  /* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
  width: 100%;
  height: 100%;
  transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
  transform: scale(0.9); /* Augmente la taille de l'image à 120% */
  opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 100%;
  }
}

.background-image {
  background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation de sortie */
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
  animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
  animation: slideInLeft 2s ease both;
}

.slide-in-right {
  animation: slideInRight 2s ease both;
}

.slide-out-left {
  animation: slideOutLeft 2s ease both;
}

.slide-out-right {
  animation: slideOutRight 2s ease both;
}
.ma-div {
  /* Ajoutez votre contenu CSS existant pour la div ici */
  width: 200px;
  height: 200px;
  background-color: transparent;
  position: relative;
  border-radius: 4px; 
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
  height: 100%;
  background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
  height: 100%;
  background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}
</style>
