<template>
  <Header />

<!-- ========== MAIN CONTENT ========== -->
<main id="content" role="main" style="position: relative; top: 120px;">
  <!-- Hero -->
  <div class="bg-img-start" style="background-images: url(assets/svg/components/card-11.svg);">
   <div :style="backgroundStyle" style="position: relative; width: 100%; height: 250px;">
      <div class="container content-space-t-3" >
      
        <div class="w-lg-75 text-center mx-lg-auto">
          <!-- Heading -->
          <div class="mb-5 mb-md-10">
            <h1 class="display-6 text-gray glowing-text" style="font-color: #fff !important">Nos valeurs</h1>
          </div>
          <!-- End Heading -->
        </div>
      </div>
    </div>
  <!-- End Gallery -->

  <div class="floating-balls" style="margin-top: 50px">
    <div class="ball" :style="{ backgroundColor: ball1.color, width: ball1.size + 'px', height: ball1.size + 'px', top: ball1.top + 'px', left: ball1.left + 'px' }"></div>
    <div class="ball" :style="{ backgroundColor: ball2.color, width: ball2.size + 'px', height: ball2.size + 'px', top: ball2.top + 'px', left: ball2.left + 'px' }"></div>
  </div>
            <!-- End Breadcrumb -->
        <!-- Info -->
        <div class="container">
            
          <nav class=" p-3" aria-label="breadcrumb" style="background: #133985; margin-top: 20px">
              <ol class="breadcrumb breadcrumb-light mb-0">
                <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/">Accueil  </router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Valeurs  </li>
              </ol>
            </nav>
          <div class="row justify-content-lg-between">
            <!-- <div class="col-md-12 card-transition h-100  ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
              <strong> <span style="color: #133985">VALEURS</span>&nbsp;
                <span style="color: #000">DE LA SEDIC</span></strong>
            </div> -->
            
            <div class="col-lg-12" style="margin-top: 40px">
              <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                <span class="d-block small font-weight-bold text-cap mb-2">Les valeurs qui guident notre approche sont les suivantes :</span>
              </div>
             
              <div class="row" >
                <div class="animated-div col-sm-6 mb-3 mb-sm-7" ref="animatedDivLeft">
                  <!-- Icon Blocks -->
                  <div class="d-flex missiondiv bg-dark" style="background-image: url(assets/svg/components/card-13.svg); color: #fff" >
                    <div class="flex-shrink-0">
                      <!-- <span>&mdash;</span> -->
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h3 class="card-title text-dark">Travail</h3>
                      <p>Cette quête d'excellence que nous
                           cultivons et qui fait notre différence est une qualité 
                           indispensable pour atteindre nos objectifs et tenir nos 
                           engagements dans les délais impartis.</p>
                    </div>
                  </div>
                  <!-- End Icon Blocks -->
                </div>

                <div class="animated-div col-sm-6 mb-3 mb-sm-7" ref="animatedDivRight">
                  <!-- Icon Blocks -->
                  <div class="d-flex missiondiv bg-dark" style="background-image: url(assets/svg/components/card-9.svg); color: #fff">
                    <div class="flex-shrink-0">
                      <!-- <span>&mdash;</span> -->
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h3 class="card-title text-dark">Innovation</h3>
                      <p>Socle de notre identité, la modernité et l'innovation sont au cœur d'une délicate équation qui consiste à renouveler notre offre et être résolument tourné vers l'avenir,
                       tout en s'inscrivant dans le respect de notre patrimoine.</p>
                    </div>
                  </div>
                  <!-- End Icon Blocks -->
                </div>  
                
                <div class="animated-div col-sm-6 mb-3 mb-sm-7 " ref="animatedDivLeft1">
                  <!-- Icon Blocks -->
                  <div class="d-flex missiondiv bg-dark" style="background-image: url(assets/svg/components/card-8.svg); color: #fff">
                    <div class="flex-shrink-0">
                      <!-- <span>&mdash;</span> -->
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h3 class="card-title text-dark">Esprit d'équipe</h3>
                      <p>C'est l'élément central qui stimule l'initiative individuelle en confiant des responsabilités importantes à chacun. Notre esprit d'équipe facilite la prise de risque et encourage la persévérance avec pragmatisme et
                       la capacité à mobiliser les équipes vers des objectifs communs.</p>
                    </div>
                  </div>
                  <!-- End Icon Blocks -->
                </div>  

                <div class="animated-div col-sm-6 mb-3 mb-sm-7 " ref="animatedDivRight1">
                  <!-- Icon Blocks -->
                  <div class="d-flex missiondiv bg-dark" style="background-image: url(assets/svg/components/card-7.svg); color: #fff">
                    <div class="flex-shrink-0">
                      <!-- <span>&mdash;</span> -->
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h3 class="card-title text-dark">Intégrité</h3>
                      <p>Représente le fondement des comportements étiques de nos équipes qui permettent ainsi d'établir des relations interpersonnelles basées sur la confiance et
                       le respect étique, avec une crédibilité professionnelle accrue.</p>
                    </div>
                  </div>
                  <!-- End Icon Blocks -->
                </div>   
              </div> 
              
            </div>

          </div>
          <!-- End Row -->
        </div>
  <!-- Appliquez la classe d'animation de sortie vers le haut lorsque isExiting est true -->
      <p style="margin-top: 150px"></p>
        <!-- End Info -->
  <!-- End Hero -->
  </div>
</main>
  
  <Footer />
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'

export default {
name: 'valeur-page',
data() {
  return {
    ball1: {
        color: 'rgba(210,228,181, 0.5)',
        size: 150,
        top: 100,
        left: 50,
      },
      ball2: {
        color: 'rgba(45,97,124, 0.5)',
        size: 80,
        top: 200,
        left: 150,
      },
    isVisible: false, // Initialisez à false pour masquer l'image initialement
    isExiting: false,
    backgroundImages: [
      'url(assets/img/tour/tourju.jpg)',
      'url(assets/img/tour/tour5.jpg)',
      'url(assets/img/slider/banni3.jpg)',
    ],
    currentIndex: 0,
    animationDuration: 4000, // Durée de l'animation en millisecondes
  };
},
computed: {
  backgroundStyle() {
    return {
      backgroundImage: this.backgroundImages[this.currentIndex],
      animation: `scrollBackground ${this.animationDuration / 400}s linear infinite`,
      backgroundSize: 'cover', // Mettre l'image en mode "cover"
    };
  },
},
methods: {
  startBackgroundScroll() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
    }, this.animationDuration);
  },
  changeBackgroundImage() {
    this.fadingOut = true;
    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
      this.fadingOut = false;
    }, 1000);
  },
checkIfVisible() {
  const element = document.querySelector('.mg');
  if (!element) return;

  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // Vérifiez si l'élément est visible à l'écran
  if (rect.top <= windowHeight && rect.bottom >= 100) {
    this.isVisible = true;
  } else {
    this.isVisible = false; // Assurez-vous de définir isVisible à false lorsque l'élément n'est pas visible

    // Si l'utilisateur quitte le pied de page en remontant, appliquez l'animation de sortie vers le haut
    if (window.scrollY < rect.top) {
      this.isExiting = true;
    } else {
      this.isExiting = false;
    }
  }
},
},
mounted() {
  this.startBackgroundScroll();
  // Écoutez l'événement de défilement pour vérifier si l'élément est visible
  window.addEventListener('scroll', this.checkIfVisible);
  // Vérifiez une première fois au chargement de la page
  this.checkIfVisible();
},
beforeUnmount() {
  // Assurez-vous de supprimer l'écouteur d'événement lorsque le composant est détruit
  window.removeEventListener('scroll', this.checkIfVisible);
},
components: {
  Header,
  Footer
}
  
}
</script>

<style>
/* Styles spécifiques à la page si nécessaire */

.floating-balls {
  position: absolute;
  width: 100%;
  height: 400px;
}

.ball {
  position: relative;
  border-radius: 50%;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.glowing-text {
font-size: 36px;
/* color: #fff; Couleur du texte */
text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
position: relative;
overflow: hidden;
/* width: 300px; /* Ajustez la largeur à votre besoin */
/* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
width: 100%;
height: 100%;
transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
transform: scale(0.9); /* Augmente la taille de l'image à 120% */
opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
0% {
  background-position: center 0;
}
100% {
  background-position: center 100%;
}
}

.background-image {
background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
from {
  transform: translateX(-100%);
  opacity: 0;
}
to {
  transform: translateX(0);
  opacity: 1;
}
}

@keyframes slideInRight {
from {
  transform: translateX(100%);
  opacity: 0;
}
to {
  transform: translateX(0);
  opacity: 1;
}
}

/* Animation de sortie */
@keyframes slideOutLeft {
from {
  transform: translateX(0);
  opacity: 1;
}
to {
  transform: translateX(-100%);
  opacity: 0;
}
}

@keyframes slideOutRight {
from {
  transform: translateX(0);
  opacity: 1;
}
to {
  transform: translateX(100%);
  opacity: 0;
}
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
from {
  transform: translateY(0);
  opacity: 1;
}
to {
  transform: translateY(-100%);
  opacity: 0;
}
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
animation: slideInLeft 2s ease both;
}

.slide-in-right {
animation: slideInRight 2s ease both;
}

.slide-out-left {
animation: slideOutLeft 2s ease both;
}

.slide-out-right {
animation: slideOutRight 2s ease both;
}
.ma-div {
/* Ajoutez votre contenu CSS existant pour la div ici */
width: 200px;
height: 200px;
background-color: transparent;
position: relative;
border-radius: 4px; 
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
height: 100%;
background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
content: "";
position: absolute;
top: 0;
right: 0;
width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
height: 100%;
background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.missiondiv {
/* Ajoutez votre contenu CSS existant pour la div ici */
background-color: transparent;
position: relative;
height: 200px;
padding-top: 20px;
padding: 20px;
text-align: justify;
color: #474646;
border-radius: 4px; 
border: 1px solid #eee;
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
transition: transform 1.5s;
}

.missiondiv:hover {
/* border: 1px solid #777676; */
transform: scale(1.07);
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}
.animated-div {
  transition: transform 0.5s;
}

.animate {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveRight 2s ease-in-out ;
}
.animateLeft {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveLeft 2s ease-in-out ;
}
.animateRight {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveRight 2s ease-in-out ;
}
@keyframes moveRight {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0); /* Déplace de 100 pixels vers la droite */
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0); /* Déplace de 100 pixels vers la droite */
  }
}

</style>
