<template>
  <vueper-slides @change="handleSlideChange" :slideContentOutside="top" :pauseOnHover="false" fade progress :touchable="false" :duration="4000" autoplay >
    <vueper-slide
      v-for="(slide, index) in slides"
      :key="index"
      :title="getImageUrl(slide.title).toUpperCase()"
      :image="getImageUrl(slide.image)"
      :class="'slide-title-' + index" />
  </vueper-slides>
</template>

<script>

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'Slide-show',
  components: { VueperSlides, VueperSlide },
  methods: {
    getImageUrl(fileName) {
      return fileName;
    },
    handleSlideChange(newIndex) {
      this.applyAnimationToActiveSlide(newIndex);
    },
    applyAnimationToActiveSlide(activeSlideIndex) {
      const slideTitles = this.$refs.slides.$el.querySelectorAll('.vueperslide__title');
      
      slideTitles.forEach((title, index) => {
        // Appliquez une classe d'animation avec un décalage (delay) différent pour chaque titre
        if (index === activeSlideIndex) {
          title.style.animation = 'slideUp 1s ease';
        } else {
          const delay = (index - activeSlideIndex) * 0.5; // Décalage de 0.5 seconde entre chaque titre
          title.style.animation = `slideUp 1s ease ${delay}s`;
        }
      });
    },
  },
  data() {
    return { 
      // autoplayOptions: {
      //   duration: 5000, // Durée de 5 secondes pour l'autoplay (modifiable selon vos besoins)
      //   pauseOnHover: true, // Option facultative pour mettre en pause l'autoplay au survol de la souris
      // },
      // In your component's data.
      slides: [
        {
          // You can also provide a URL for the image.
          title: "Maquette - Brazza Trade Center (Tours Jumelles)",
          image: 'assets/img/slider/banni.jpg'
        },
        {
          // You can also provide a URL for the image.
          title: "Entrée - Brazza Trade Center (Tours Jumelles)",
          image: 'assets/img/slider/slider8.jpg'
        },
        // {
        //   // You can also provide a URL for the image.
        //   title: "Vue Panoramique - Centre International de Conférence de Kintélé",
        //   image: 'assets/img/slider/slider3.jpg'
        // },
        {
          // You can also provide a URL for the image.
          title: "Vue aérienne sur le fleuve - Brazza Trade Center (Tours Jumelles)",
          image: 'assets/img/slider/slider7.jpg'
        },
        // {
        //   // You can also provide a URL for the image.
        //   title: "Auditorium - Centre International de Conférence de Kintélé",
        //   image: 'assets/img/slider/slider12.jpg'
        // },
        {
          // You can also provide a URL for the image.
          title: "Salle de réunion - Brazza Mall",
          image: 'assets/img/slider/slider11.jpg'
        },
        // {
        //   // You can also provide a URL for the image.
        //   title: "Centre International de Conférence de Kintélé",
        //   image: 'assets/img/slider/slider2.jpg'
        // },
        {
          // You can also provide a URL for the image.
          title: "Salle des Présidents - Centre International de Conférence de Kintélé",
          image: 'assets/img/slider/slider13.jpg'
        },
        {
          // You can also provide a URL for the image.
          title: "Logements sociaux de MPILA",
          image: 'assets/img/slider/banni2.jpg'
        },
        {
          // You can also provide a URL for the image.
          title: "Pont du 15 aôut du 1960",
          image: 'assets/img/slider/banni3.jpg'
        },
      ]
    };
  },
  mounted() {
    // this.applyAnimationToActiveSlide(0); // Appliquer l'animation à la première diapositive au montage
  },
  // mounted() {
  //   // Après le montage du composant
  //   this.$nextTick(() => {
  //     // Appelez la méthode reSlick sur la référence
  //     this.$refs.slick.reSlick();
  //   });
  // },
};
</script>

<style>/* You can add your own style here */

/* .custom-slide {
  font-size: 30px;
  font-weight: 900;
} */
/* Appliquer l'animation à chaque titre de diapositive */
.slide-title-0 {
  animation: slideUp 3s ease; 
  /* 1s de durée d'animation avec une transition en douceur */
  /* color: red; */
}
.slide-title-1 {
  /* animation: slideUp1 3s ease; 1s de durée d'animation avec une transition en douceur */
  /* color: blue; */
}
.slide-title-2 {
  animation: slideUp2 3s ease; /* 1s de durée d'animation avec une transition en douceur */
  /* color: black; */
}
.slide-title-3 {
  /* animation: slideUp3 3s ease; 1s de durée d'animation avec une transition en douceur */
  /* color: green; */
}
.slide-title-4 {
  /* animation: slideUp4 3s ease; 1s de durée d'animation avec une transition en douceur */
  /* color: white; */
}
.vueperslide__title {
  font-size: 20px;
  opacity: 0.9;
  font-weight: 900;
  position: absolute;
  display: block;
  color: #ffffff;
  text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.9);
  right: 10px;
  top: 10px;
  animation: slideUp 1s ease; 
}

/* Définition de l'animation d'apparition du bas vers le haut */
@keyframes slideUp {
  from {
    transform: translateY(100%); /* Déplacez le texte vers le bas de 100% de sa hauteur */
    opacity: 0; /* Le texte est complètement transparent au début */
  }
  to {
    transform: translateY(0%); /* Le texte est à sa position normale (0%) */
    opacity: 1; /* Le texte est complètement visible à la fin */
  }
}
@keyframes slideUp1 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
    animation-timing-function: ease-out;
  }
}
@keyframes slideUp2 {
  0%, 100% {
    transform: translateY(0%);
    opacity: 0.2;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
}
@keyframes slideUp3 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  20%, 53%, 80%, to {
    transform: translateY(0%);
    opacity: 1;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
@keyframes slideUp4 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0.8;
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes slideUp5 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  30%, 65%, 85%, to {
    transform: translateY(0%);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
}
@keyframes slideUp6 {
  0%, 100% {
    transform: translateY(0%);
    opacity: 0.5;
  }
  10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateY(-5px);
    opacity: 1;
  }
}
@keyframes slideUp7 {
  0%, 100% {
    transform: translateY(0%);
    opacity: 0;
  }
  20%, 80% {
    transform: translateY(-10px);
    opacity: 1;
  }
}
@keyframes slideUp8 {
  0%, 100% {
    transform: translateY(0%);
    opacity: 0;
  }
  20%, 80% {
    transform: translateY(-10px);
    opacity: 1;
  }
}
@keyframes slideUp9 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  15%, 35%, 55%, 75%, 95% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes slideUp10 {
  0%, 100% {
    transform: translateY(0%);
    opacity: 0;
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

</style>
