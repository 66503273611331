<template>
    <Header />

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" role="main" style="position: relative; top: 120px;">
    <!-- Hero -->
    <div class="bg-img-start" style="background-images: url(assets/svg/components/card-11.svg);">
     <div :style="backgroundStyle" style="position: relative; width: 100%; height: 250px;">
        <div class="container content-space-t-3" >
        
          <div class="w-lg-75 text-center mx-lg-auto">
            <!-- Heading -->
            <div class="mb-5 mb-md-10">
              <h1 class="display-6 text-gray glowing-text" style="font-color: #fff !important">Qui sommes nous</h1>
            </div>
            <!-- End Heading -->
          </div>
        </div>
      </div>
    <!-- End Gallery -->
    <div class="floating-balls" style="margin-top: 130px">
    <div class="ball" :style="{ backgroundColor: ball1.color, width: ball1.size + 'px', height: ball1.size + 'px', top: ball1.top + 'px', left: ball1.left + 'px' }"></div>
    <div class="ball" :style="{ backgroundColor: ball2.color, width: ball2.size + 'px', height: ball2.size + 'px', top: ball2.top + 'px', left: ball2.left + 'px' }"></div>
  </div>

              <!-- End Breadcrumb -->
          <!-- Info -->
          <div class="container">
            
            <nav class=" p-3" aria-label="breadcrumb" style="background: #133985; margin-top: 20px">
              <ol class="breadcrumb breadcrumb-light mb-0">
                <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/">Accueil  </router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Qui Sommes Nous  </li>
              </ol>
            </nav>
            <div class="row justify-content-lg-between">
              <!-- <div class="col-lg-2 mb-5 mb-lg-0">
                <h2>Historique</h2>
              </div> -->
              <!-- End Col -->
              <!-- <div class="slide-in-right col-md-12 card-transition h-100  ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
                <strong> <span style="color: #133985">PRESENTATION</span>&nbsp;
                  <span style="color: #000">DE LA SEDIC</span></strong>
              </div> -->
              
              <div class="slide-in-right bg-light col-md-8 card-transition h-100  ma-div" style="background-image: url(assets/svg/components/circled-stripe.svg); margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
                <!-- <p style="">DE LA SEDIC</p> -->
                <p style=" font-weight: 500; font-size: 16px; text-align: center;">La SEDIC a été créée en 2022 à la suite de la volonté de l'État congolais de trouver des solutions alternatives pour le développement et la gestion des diverses infrastructures établies au Congo.
                    </p>
              </div>
              <div class=" col-lg-4 " >
              </div>

              <!-- <div  class=" col-lg-12" > 
                <div class="bg-img-start rounded-2" :style="backgroundStyle"
                  style="background-image: url(assets/img/9.jpg);
                  background-size: cover; height: 12rem;">
                </div>
              </div> -->
              <div class=" col-lg-2 " >
              </div>
              <div class="slide-in-left col-lg-10 bg-dark rounded-2 nousdiv" style="background-image: url(assets/svg/components/wave-pattern-light.svg);">
                <div class="container-xl container-fluid content-space-1 content-space-md-2 px-4 px-md-8 px-lg-10" style="color: #fff">
                    
                    <p  style="text-align: justify;">En effet, cette société de gestion de patrimoine a pour mission d'assurer
                      la maintenance, l'exploitation et le développement des infrastructures du Congo.
                        La directrice générale de la SEDIC est Madame Myriam Denguet.
                      La société compte une dizaine de salariés.</p>
                    <p  style="text-align: justify;">Parmi les infrastructures gérées par la SEDIC, on peut citer les
                      Tours Jumelles, le Brazza Mall, 
                      les logements de M'pila, ainsi que le Grand Hôtel de Kintele.</p>
                      
                    <p style="text-align: justify;">La SEDIC, officiellement nommée Société d'Exploitation et de Développement 
                    des Infrastructures du Congo, a été fondée en 2022 avec pour missions principales
                    la gestion patrimoniale. Son rôle essentiel consiste à assurer la maintenance,
                      l'exploitation et la croissance des diverses infrastructures au Congo.
                    </p>
                </div> 
              </div> 
            </div> 
              
            <div class="row align-items-md-center"  >
              <div class="col-md-12 card-transition h-100 ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
                <strong> <span style="color: #133985">MOT</span>&nbsp;
                  <span style="color: #000">DE LA DIRECTRICE</span></strong>
              </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-7 nousdiv bg-light animated-div" ref="animatedDivRight" style="background-image: url(assets/svg/components/wave-pattern-light.svg);">
                  <!-- Blockquote -->
                  <div class="pe-md-7">

                    <p style="text-align: justify;">C'est avec une grande fierté et un profond engagement que je m'adresse à vous en tant que directrice de la Société d'Exploitation et de Développement des Infrastructures du Congo (SEDIC). Je suis convaincue que notre travail acharné, notre esprit d'équipe et notre engagement envers l'intégrité sont les piliers de notre succès continu."</p>
                    <p style="text-align: center; padding-left: 50px; padding-right: 50px; font-weight: 600; font-size: 17px">Ensemble, nous poursuivons notre mission de veiller à la maintenance, à l'exploitation et au développement des infrastructures qui contribuent à l'évolution positive de notre cher pays. Les défis sont nombreux, mais je suis confiante que notre détermination et notre créativité nous permettront de les surmonter avec succès.</p>
                    <!-- <p style="text-align: justify;">Je tiens à vous remercier pour votre dévouement et votre contribution précieuse à chaque aspect de notre travail. Ensemble, nous façonnons l'avenir et la prospérité de la SEDIC. N'oublions jamais que nos actions influencent directement la qualité de vie de nos concitoyens.</p> -->
                    <!-- <p style="text-align: justify;">Je suis honorée de diriger cette équipe exceptionnelle et je suis impatiente de voir les réalisations que nous accomplirons ensemble dans les jours à venir. Merci de votre engagement et de votre dévouement.</p> -->
                    <p style="text-align: center; color:#133985">« Notre credo est de mettre à disposition notre professionnalisme et notre expertise au service du développement
                       économique du Congo par le biais de ses infrastructures» <br><br><br> 

                    <!-- Avec toute ma gratitude. -->
                    </p>

                    <!-- <div class="blockquote-footer">
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 d-md-none">
                          <img class="avatar avatar-circle" src="assets/img/160x160/img1.jpg" alt="Image Description">
                        </div>
                        <div class="flex-grow-1 ms-3 ms-md-0">
                          Bénédicte Myriam DENGUET-ATTICKY
                          <span class="blockquote-footer-source">Directrice Général de la SEDIC</span>
                        </div>
                      </div>
                    </div> -->
                      <blockquote class="blockquote" style="text-align: center; color:#133985; font-weight: 700;">
                        <p> Bénédicte Myriam DENGUET-ATTICKY</p>
                      </blockquote>
                      <figcaption class="blockquote-footer" style="text-align: center; font-size: 14px">
                        Directrice Générale de la<cite title="Source Title"> SEDIC</cite>
                      </figcaption>
                  </div>
                  <!-- End Blockquote -->
                </div>
              <div class="image-zoom-container  col-md-4 d-none d-md-block  animated-div" ref="animatedDivLeft">
                <img class="image-zoom " style="height: 350px; position: relative; left: -70px; z-index: 10000; border-radius: 50%" src="assets/img/myriam.jpg" alt="Image Description">
              </div>
            </div> 
            <!-- End Row -->
          </div>
    <!-- Appliquez la classe d'animation de sortie vers le haut lorsque isExiting est true -->
        <p style="margin-top: 150px"></p>
          <!-- End Info -->
    <!-- End Hero -->
    </div>
  </main>
    
    <Footer />
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'

export default {
  name: 'nous-page',
  data() {
    return {
    ball1: {
        color: 'rgba(210,228,181, 0.5)',
        size: 150,
        top: 100,
        left: 50,
      },
      ball2: {
        color: 'rgba(45,97,124, 0.5)',
        size: 80,
        top: 200,
        left: 150,
      },
      isVisible: false, // Initialisez à false pour masquer l'image initialement
      isExiting: false,
      backgroundImages: [
      'url(assets/img/tour/tourju.jpg)',
        'url(assets/img/tour/tour5.jpg)',
        'url(assets/img/slider/banni3.jpg)',
      ],
      currentIndex: 0,
      animationDuration: 4000, // Durée de l'animation en millisecondes
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: this.backgroundImages[this.currentIndex],
        animation: `scrollBackground ${this.animationDuration / 400}s linear infinite`,
        backgroundSize: 'cover', // Mettre l'image en mode "cover"
      };
    },
  },
  methods: {
    // Cette fonction sera appelée lorsque la div entre ou sort de la vue
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // La div est entrée dans la vue
          // Vous pouvez ici déclencher une animation, par exemple en ajoutant une classe CSS
          this.$refs.animatedDivLeft.classList.add('animateLeft');
          this.$refs.animatedDivRight.classList.add('animateRight'); 
          // this.$refs.animatedDiv.classList.add('animate');
        } else {
          // La div est sortie de la vue
          // Vous pouvez ici inverser l'animation, par exemple en supprimant la classe CSS
          this.$refs.animatedDivLeft.classList.remove('animateLeft');
          this.$refs.animatedDivRight.classList.remove('animateRight');
        }
      });
    },
    startBackgroundScroll() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
      }, this.animationDuration);
    },
    changeBackgroundImage() {
      this.fadingOut = true;
      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
        this.fadingOut = false;
      }, 1000);
    },
  checkIfVisible() {
    const element = document.querySelector('.mg');
    if (!element) return;

    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Vérifiez si l'élément est visible à l'écran
    if (rect.top <= windowHeight && rect.bottom >= 1600) {
      this.isVisible = true;
    } else {
      this.isVisible = false; // Assurez-vous de définir isVisible à false lorsque l'élément n'est pas visible

      // Si l'utilisateur quitte le pied de page en remontant, appliquez l'animation de sortie vers le haut
      if (window.scrollY < rect.top) {
        this.isExiting = true;
      } else {
        this.isExiting = false;
      }
    }
  },
  },
  mounted() {
    
    // Créez une instance de l'IntersectionObserver
    const options = {
      root: null, // Utilise la fenêtre comme zone d'observation
      rootMargin: '0px', // Aucune marge supplémentaire
      threshold: 0.5, // 0.5 signifie que 50% de la div doit être visible pour déclencher l'animation
    };

    const observer = new IntersectionObserver(this.handleIntersection, options);

    // Commencez à observer la div
    // observer.observe(this.$refs.animatedDiv);
    observer.observe(this.$refs.animatedDivLeft);
    observer.observe(this.$refs.animatedDivRight);
    this.startBackgroundScroll();
    // Écoutez l'événement de défilement pour vérifier si l'élément est visible
    window.addEventListener('scroll', this.checkIfVisible);
    // Vérifiez une première fois au chargement de la page
    this.checkIfVisible();
  },
  beforeUnmount() {
    // Assurez-vous de supprimer l'écouteur d'événement lorsque le composant est détruit
    window.removeEventListener('scroll', this.checkIfVisible);
  },
  components: {
    Header,
    Footer
  }
    
}
</script>

<style>
/* Styles spécifiques à la page si nécessaire */

.floating-balls {
  position: absolute;
  width: 100%;
  height: 400px;
}

.ball {
  position: relative;
  border-radius: 50%;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.glowing-text {
  font-size: 36px;
  /* color: #fff; Couleur du texte */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
  position: relative;
  overflow: hidden;
  /* width: 300px; /* Ajustez la largeur à votre besoin */
  /* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
  width: 100%;
  transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
  transform: scale(0.9); /* Augmente la taille de l'image à 120% */
  opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 100%;
  }
}

.background-image {
  background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation de sortie */
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
  animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
  animation: slideInLeft 2s ease both;
}

.slide-in-right {
  animation: slideInRight 2s ease both;
}

.slide-out-left {
  animation: slideOutLeft 2s ease both;
}

.slide-out-right {
  animation: slideOutRight 2s ease both;
}
.ma-div {
  /* Ajoutez votre contenu CSS existant pour la div ici */
  width: 200px;
  height: 200px;
  /* background-color: transparent; */
  position: relative;
  border-radius: 4px; 
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
  height: 100%;
  background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
  height: 100%;
  background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}
.nousdiv {
/* Ajoutez votre contenu CSS existant pour la div ici */
background-color: transparent;
padding: 10px;
text-align: justify;
color: #474646;
border-radius: 4px; 
border: 1px solid #eee;
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
transition: transform 1.5s;
}

.nousdiv:hover {
/* border: 1px solid #777676; */
transform: scale(1.07);
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}
.animated-div {
  transition: transform 0.5s;
}

.animateLeft {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveLeft 2s ease-in-out ;
}
.animateRight {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveRight 2s ease-in-out ;
}
@keyframes moveRight {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0); /* Déplace de 100 pixels vers la droite */
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0); /* Déplace de 100 pixels vers la droite */
  }
}

</style>
