<template>
  <Header />

<!-- ========== MAIN CONTENT ========== -->
<main id="content" role="main" style="position: relative; top: 120px;">
  <!-- Hero -->
  <div class="bg-img-start" style="background-images: url(assets/svg/components/card-11.svg);">
   <div :style="backgroundStyle" style="position: relative; width: 100%; height: 350px;">
      <div class="container content-space-t-3" >
      
        <div class="w-lg-75 text-center mx-lg-auto">
          <div class="mb-5 mb-md-10">
            <h1 class="display-6 text-gray glowing-text" style="font-color: #fff !important">Focus</h1>
          </div>
        </div>
      </div>
    </div>
  <!-- End Gallery -->

            <!-- End Breadcrumb -->
        <!-- Info -->
        <div class="container">
          
          <div class="row justify-content-lg-between">
            <!-- <div class="col-lg-2 mb-5 mb-lg-0">
              <h2>Historique</h2>
            </div> -->
            <!-- End Col -->
            <!-- <div class="col-md-12 card-transition h-100  ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
              <strong> <span style="color: #133985">PRESENTATION</span>&nbsp;
                <span style="color: #000">DU MUSEE DE L'HISTOIRE DU CONGO</span></strong>
            </div> --><br><br>
            <span style="color: #000">Page en construction</span>
          <br><br><br><br><br><br>
            <!-- End Col -->
          </div> 
            

            
          <!-- End Row -->
        </div>
  <!-- Appliquez la classe d'animation de sortie vers le haut lorsque isExiting est true -->
      <p style="margin-top: 150px"></p>
        <!-- End Info -->
  <!-- End Hero -->
  </div>
</main>
  
  <Footer />
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'

export default {
name: 'nous-page',
data() {
  return {
    isVisible: false, // Initialisez à false pour masquer l'image initialement
    isExiting: false,
    backgroundImages: [
      'url(assets/img/banactu/banactu2.jpg)',
     ],
    currentIndex: 0,
    animationDuration: 4000, // Durée de l'animation en millisecondes
  };
},
computed: {
  backgroundStyle() {
    return {
      backgroundImage: this.backgroundImages[this.currentIndex],
      animation: `scrollBackground ${this.animationDuration / 400}s linear infinite`,
      backgroundSize: 'cover', // Mettre l'image en mode "cover"
    };
  },
},
methods: {
  startBackgroundScroll() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
    }, this.animationDuration);
  },
  changeBackgroundImage() {
    this.fadingOut = true;
    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
      this.fadingOut = false;
    }, 1000);
  },
checkIfVisible() {
  const element = document.querySelector('.mg');
  if (!element) return;

  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // Vérifiez si l'élément est visible à l'écran
  if (rect.top <= windowHeight && rect.bottom >= 1600) {
    this.isVisible = true;
  } else {
    this.isVisible = false; // Assurez-vous de définir isVisible à false lorsque l'élément n'est pas visible

    // Si l'utilisateur quitte le pied de page en remontant, appliquez l'animation de sortie vers le haut
    if (window.scrollY < rect.top) {
      this.isExiting = true;
    } else {
      this.isExiting = false;
    }
  }
},
},
mounted() {
  this.startBackgroundScroll();
  // Écoutez l'événement de défilement pour vérifier si l'élément est visible
  window.addEventListener('scroll', this.checkIfVisible);
  // Vérifiez une première fois au chargement de la page
  this.checkIfVisible();
},
beforeUnmount() {
  // Assurez-vous de supprimer l'écouteur d'événement lorsque le composant est détruit
  window.removeEventListener('scroll', this.checkIfVisible);
},
components: {
  Header,
  Footer
}
  
}
</script>

<style>
/* Styles spécifiques à la page si nécessaire */
.glowing-text {
font-size: 36px;
/* color: #fff; Couleur du texte */
text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
position: relative;
overflow: hidden;
/* width: 300px; /* Ajustez la largeur à votre besoin */
/* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
width: 100%;
height: 100%;
transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
transform: scale(0.9); /* Augmente la taille de l'image à 120% */
opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
0% {
  background-position: center 0;
}
100% {
  background-position: center 100%;
}
}

.background-image {
background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
from {
  transform: translateX(-100%);
  opacity: 0;
}
to {
  transform: translateX(0);
  opacity: 1;
}
}

@keyframes slideInRight {
from {
  transform: translateX(100%);
  opacity: 0;
}
to {
  transform: translateX(0);
  opacity: 1;
}
}

/* Animation de sortie */
@keyframes slideOutLeft {
from {
  transform: translateX(0);
  opacity: 1;
}
to {
  transform: translateX(-100%);
  opacity: 0;
}
}

@keyframes slideOutRight {
from {
  transform: translateX(0);
  opacity: 1;
}
to {
  transform: translateX(100%);
  opacity: 0;
}
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
from {
  transform: translateY(0);
  opacity: 1;
}
to {
  transform: translateY(-100%);
  opacity: 0;
}
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
animation: slideInLeft 2s ease both;
}

.slide-in-right {
animation: slideInRight 2s ease both;
}

.slide-out-left {
animation: slideOutLeft 2s ease both;
}

.slide-out-right {
animation: slideOutRight 2s ease both;
}
.ma-div {
/* Ajoutez votre contenu CSS existant pour la div ici */
width: 200px;
height: 200px;
background-color: transparent;
position: relative;
border-radius: 4px; 
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
height: 100%;
background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
content: "";
position: absolute;
top: 0;
right: 0;
width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
height: 100%;
background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}
</style>
