<template>
        <Header/>

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" role="main" style="position: relative; top: 120px">
    <!-- Hero -->
    
    <Sliders></Sliders>
    
    <!-- Testimonials -->
    <div class="overflow-hidden" style="margin-top: 20px">
      <div class="container content-space-b-2">
        <div class="position-relative">
          <div v-if="isDesktop" class=" text-center rounded-2 p-4 p-md-7 " style="height:150px; overflow: hidden; background-color: #133985; z-index: 1000">
            <figure class="w-md-80 w-lg-50 mx-md-auto" >
              <blockquote class="blockquote card-transition h-100" style="border: 0px solid #000; width: 65%; top: 45px; left: 10px; position: absolute; color: #fff">
              <!-- SOCIETE D'EXPLOITATION ET DE DEVELOPPEMENT DES INFRASTRUCTURES DU CONGO -->
                <v-typical
                  class="typicalWrapper"
                  :steps="['SEDIC', 1000, 'SOCIETE D\'EXPLOITATION', 500, 'SOCIETE D\'EXPLOITATION ET DE DEVELOPPEMENT DES INFRASTRUCTURES DU CONGO', 1000]"
                  :loop="10"
                  :wrapper="'h3'"
                ></v-typical>
              </blockquote>
                <transition name="image-fade">
                  <img @mouseenter="imageHover = true" @mouseleave="imageHover = false" style="position: absolute; right: 10px; top: 0px; width: 320px!important; height: 150px!important" class="card-transition" src="assets/img/logoblanc.png" alt="Logo">
                </transition>
            </figure>
          </div>
          <div v-else class=" text-center rounded-2 p-4 p-md-7 " style="height:150px; overflow: hidden; background-color: #133985; z-index: 1000">
            <figure class="w-md-80 w-lg-50 mx-md-auto" >
              <img style="position: absolute; right: 10px; top: 0px; width: 320px!important; height: 150px!important" class="" src="assets/img/logoblanc.png" alt="Logo">
            </figure>
          </div>
          
          <!-- SVG Shape -->
          <div class="position-absolute bottom-0 start-0 w-100" style="max-width: 7rem;">
            <div class="mb-n7 ms-n7">
              <img class="img-fluid" src="assets/svg/components/dots.svg" alt="Image Description">
            </div>
          </div>
          <!-- End SVG Shape -->
        </div>
      </div>
    </div>
    <!-- End Testimonials -->

    <!-- Features -->
    <div class="container" style="margin-top: -20px">
      <div class="row justify-content-lg-between align-items-lg-center">
        <!-- <div class="col-lg-12 mb-9 mb-lg-0">
          <div v-if="isDesktop" class="mb-5">
              <h1 class="h4 text-center" style="font-size: 20px; height: 25px; color: #000!important">
                Nous sommes une société de gestion de patrimoine, nous assurons &nbsp;<span style="color: #133985">{{ currentText }}</span>&nbsp;</h1>
              <h1 class="h4 text-center" style="font-size: 20px; height: 25px; color: #000!important">
                des différentes infrastructures congolaises</h1><br>
          </div>
          <div v-else class="mb-5">
              <h1 class="h4 text-center" style="font-size: 20px; height: 25px; color: #000!important; margin-bottom: 23px">
                Nous sommes une société de gestion de patrimoine, nous assurons &nbsp;<span style="color: #133985">{{ currentText }}</span>&nbsp;</h1>
              <h1 class="h4 text-center" style="font-size: 20px; height: 25px; color: #000!important">
                des différentes infrastructures congolaises</h1><br>
          </div>
        </div>  -->
        
        <div class="col-12 mb-3 mb-sm-7">
          <!-- Card -->
          <div class="card card-flush">
            <div class="row align-items-md-center">
              <div class="col-md-8 animated-div" ref="animatedDivLeft">
                <img class="card-img rounded-2" src="assets/img/ministre.jpg" alt="Image Description">
              </div>
              <!-- End Col -->

              <div class="col-md-4 animated-div" ref="animatedDivRight">
                <div class="card-body">
                  <h2 class="card-title" style="font-size: 20px; text-transform: uppercase">
                  <router-link class="text-dark" to="/quisommesnous">
                  Mot du Ministre
                  </router-link>
                  </h2>
                  <span class="card-subtitle"></span> <br>
                  <p class="card-text" style="text-align:justify">Lorem ipsum dolor sit amet, 
                  consectetur adipiscing elit. Fusce vel bibendum quam. Sed auctor, erat eu scelerisque
                   pharetra, ex dolor varius justo, eu laoreet odio justo ac arcu. Aliquam vel aliquam
                    tortor. Sed convallis orci non ligula pellentesque, ac auctor libero iaculis.
                     Suspendisse potenti. Nulla facilisi. Proin eleifend est nec lorem convallis,
                      vel posuere tellus bibendum. Vestibulum vel dapibus elit. Quisque vel malesuada
                       libero. Nullam bibendum erat id ante cursus, in eleifend nisl egestas.
                        Vivamus varius vitae justo ut laoreet. Integer vehicula odio non mi cursus,
                         et sodales velit tincidunt. Curabitur eget
                   suscipit tellus, vel tristique libero. In hac habitasse platea dictumst.</p>
                  <!-- <router-link class="card-link" to="/quisommesnous">Lire la suite <i class="bi-chevron-right small ms-1"></i></router-link> -->
                </div>
              </div>  
              <!-- End Col -->
            </div>
            <!-- End Row -->
                  
          </div>
          <!-- End Card -->

        </div>
        <!-- End Col -->
      </div>
    </div>
    <!-- End Features -->
    <!-- Card Grid -->
    <!-- <div class="container" style="margin-top: -45px !important">

      <div class="row gx-3 mb-5 mb-md-9" > -->
              <!-- <div class="col-md-12 card-transition h-100  ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
                <strong> <span class="half-border" style="font-size: 17px; margin-bottom: 20px; color: #133985">ACT</span>
                  <span style="color: #000">UALITES</span></strong>
              </div> -->
              <!-- <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0" >
                <a class="card card-transition h-100" href="#">
                  <img class="card-img-top" src="assets/img/1920X1080/actu4.jpg" alt="">
                  <div class="card-body">
                    <h5 class="card-text lh-base" style="padding: 10px; border-radius: 4px; 
                      text-align: center; color: #fff; letter-spacing: 1px; background-color: rgba(0,0,0, 0.8);font-weight: 900">L'inauguration imminente des tours jumelles</h5>
                  </div>
                </a>
              </div> -->

              <!-- <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                 <a class="card card-transition h-100" href="#">
                  <img class="card-img-top" src="assets/img/1920X1080/actu1.jpg" alt="">
                  <div class="card-body">
                   <h5 class="card-text lh-base" style="padding: 10px; border-radius: 4px; 
                      text-align: center; color: #fff; letter-spacing: 1px; background-color: rgba(0,0,0, 0.8);font-weight: 900">L'inauguration de Brazza mal très bientôt</h5>
                  </div>
                </a>
              </div> -->

              <!-- <div class="col-sm-6 col-lg-3 mb-3 mb-sm-0">
                <a class="card card-transition h-100" href="#">
                  <img class="card-img-top" src="assets/img/1920X1080/actu2.jpg" alt="">
                  <div class="card-body">
                    <h5 class="card-text lh-base" style="padding: 10px; border-radius: 4px; 
                      text-align: center; color: #fff; letter-spacing: 1px; background-color: rgba(0,0,0, 0.8);font-weight: 900">Sommet des 3 bassins  à Brazzaville du 26 au 28 Octobre 2023 </h5>
                  </div>
                </a>
              </div> -->

              <!-- <div class="col-sm-6 col-lg-3">
                <a class="card card-transition h-100" href="#">
                  <img class="card-img-top" src="assets/img/1920X1080/actu3.jpg" alt="">
                  <div class="card-body">
                    <h5 class="card-text lh-base" style="padding: 10px; border-radius: 4px; 
                      text-align: center; color: #fff; letter-spacing: 1px; background-color: rgba(0,0,0, 0.8);font-weight: 900">Une offre spéciale à partir de septembre sur le programme immobilier de mpila</h5>
                  </div>
                </a>
              </div> -->
        
              <!-- <vueper-slides class="no-shadow" 
                slide-multiple :gap="3" :visible-slides="3" :slide-ratio="1 / 4" infinite autoplay :dragging-distance="200" :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }">
                <vueper-slide  v-for="(slide, i) in slides"
                  :key="i"
                  :image="slide.image"
                  :title="slide.title"
                  :link="slide.link"
                   class="custom-slide" />
              </vueper-slides><br><br> -->
      <!-- </div>
    </div> -->


    <!-- <vueper-slides class="no-shadow"
      :visible-slides="3"
      :slide-ratio="1 / 4"
      :dragging-distance="70" >
      <vueper-slide
      v-for="(slide, index) in slides"
      :key="index"
      :image="getImageUrl(slide.image)"
       />
  </vueper-slides> -->
  

    <!-- Gallery -->
    <!-- <div class="position-relative bg-img-start" style=" top: -60px; background-image: url(assets/svg/components/card-11.svg);">
      <div class="container  position-relative zi-2">
        <div class="row gx-2">
        <div class="col-4 align-self-end mb-2">
            <div class="ms-md-8">
              <img class="img-fluid rounded-2" src="assets/img/4.jpg" alt="Image Description">
            </div>
          </div>

          <div class="col-8 mb-2">  
            <img class="img-fluid rounded-2" src="assets/img/3.jpg" alt="Image Description">
          </div>

          <div class="col-8 mb-2">
            <img class="img-fluid rounded-2" src="assets/img/8.jpg" alt="Image Description">
          </div>

          <div class="col-4 mb-2">
            <div class="me-md-8">
              <img class="img-fluid rounded-2" src="assets/img/6.jpg" alt="Image Description">
            </div>
          </div>
        </div>
      </div>

      <div class="shape shape-bottom zi-1">
        <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 500H3000V0L0 500Z" fill="#fff" />
        </svg>
      </div>
    </div> -->
    <!-- End Gallery -->
<!-- Card Grid -->


    <!-- Card Grid -->
    <div class="container content-space-1 " style="margin-top: -25px !important">

      <div class="row gx-3 mb-5 mb-md-9" >
        <h2 class="card-subtitle " style="font-size: 20px; font-weight: 900;  margin-bottom: 20px; ">Actualités</h2>
        <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0" v-for="(slide, i) in slides"
                        :key="i">
          <!-- Card -->
          <router-link class="card card-transition h-100" :to="slide.link">
            <img class="card-img-top"  :src="slide.image" alt="">
            <div class="card-body">
              <span class="card-subtitle text-primary">{{slide.category}}</span>
              <h5 class="card-text lh-base">{{slide.title}}</h5>
            </div>
          </router-link>
          <!-- End Card -->
        </div>
        <!-- End Col -->

      </div>
      <!-- End Row -->
    </div>

  </main><br><br><br>
  <!-- ========== END MAIN CONTENT ========== -->
        <Footer /> 
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'
import Sliders from '../components/Slider-View.vue'

import VTypical from 'vue-typical';
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
// import { ref } from 'vue'

import { mapGetters } from 'vuex';
import { ref, onMounted } from 'vue';

export default {
  name: 'accueil-page',
  data() {
            return {
                fullPage: true,
                // videoPath: './assets/video/video2.mp4', // Chemin de la vidéo
                texts: ["la maintenance", "l'exploitation", "le développement"
                // "de notre PERFORMANCE",
                //  "La SEDIC, la référence en matière de gestion des infrastructures", 
                //  "de notre EXPERTISE"
                 ],
                observer: null,
                imageHover: false,
                currentTextIndex: 0,
                currentText: "",
                fadeInDuration: 50, // Durée du fondu à l'écriture (en millisecondes)
                fadeOutDuration: 20, // Durée du fondu à l'effacement (en millisecondes)
                timeElapsed: 0,
                isDesktop: false,
                slides: [
                  {
                    // You can also provide a URL for the image.
                    // image: "https://sedic.astraas.com/actu/actu4.jpg",
                    image: `assets/img/1920x1080/actu4.jpg`,
                    title: "L'inauguration imminente des tours jumelles",
                    link: "actu1",
                    category: "Evénement"
                  },
                  {
                    // You can also provide a URL for the image.
                    // image: "https://sedic.astraas.com/actu/actu1.jpg",
                    image: `assets/img/1920x1080/actu1.jpg`,
                    title: "L'inauguration de Brazza Mal très bientôt",
                    link: "actu2",
                    category: "Evénement"
                  },
                  {
                    // You can also provide a URL for the image.
                    // image: "https://sedic.astraas.com/actu/actu2.jpg",
                    image: `assets/img/1920x1080/actu2.jpg`,
                    title: 'Sommet des 3 bassins à Brazzaville du 26 au 28 Octobre 2023',
                    link: "actu3",
                    category: "Evénement"
                  },
                  {
                    // You can also provide a URL for the image.
                    // image: "https://sedic.astraas.com/actu/actu3.jpg",
                    // image: `${process.env.BASE_URL}assets/img/1920X1080/actu3.jpg`,
                    image: `assets/img/1920x1080/actu3.jpg`,
                    title: 'Une offre spéciale à partir de septembre sur le programme immobilier de mpila',
                    link: "actu4",
                    category: "Annonce"
                  }
                ]
            }
        },
  computed: {
    isTyping() {
      // Mettez ici votre condition pour démarrer ou arrêter la saisie
      // Par exemple, vous pouvez utiliser une variable pour contrôler cela
      return true; // Changez cette valeur en fonction de votre logique
    },
    ...mapGetters(['isVideoReady', 'backgroundImage', 'videoPath']),
    showVideo() {
      return this.isVideoReady || this.timeElapsed >= 3000;
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.playVideo();
    // }, 5000); // Délai de 3000 millisecondes (3 secondes)
    
    this.$nextTick(() => {
        const animatedDiv = this.$refs.animatedDivRight;
        if (animatedDiv) {
          animatedDiv.classList.add('animateRight');
        }
      });
    // Créez une instance de l'IntersectionObserver
    const options = {
      root: null, // Utilise la fenêtre comme zone d'observation
      rootMargin: '0px', // Aucune marge supplémentaire
      threshold: 0.5, // 0.5 signifie que 50% de la div doit être visible pour déclencher l'animation
    };
    this.observer = new IntersectionObserver(this.handleIntersection, options);
    // Commencez à observer les éléments lorsque le composant est monté
    this.startObserving();
    // const observer = new IntersectionObserver(this.handleIntersection, options);

    // Commencez à observer la div
    // observer.observe(this.$refs.animatedDiv);
    // observer.observe(this.$refs.animatedDivLeft);
    // observer.observe(this.$refs.animatedDivRight);

    this.startTyping();
    this.isDesktop = window.innerWidth > 768
    window.addEventListener('resize', () => {
      this.isDesktop = window.innerWidth > 768
    })
  },
beforeUnmount() {
  // Arrêtez l'observation lorsque vous quittez le composant
  this.stopObserving();
},
    components: {
        Header,
        Footer,
        Sliders,
        VTypical,
        // VueperSlides,
        // VueperSlide
    },
  created() {
    this.loadVideoInBackground();
  },
  methods: {
    // Cette fonction sera appelée lorsque la div entre ou sort de la vue
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // La div est entrée dans la vue
          // Vous pouvez ici déclencher une animation, par exemple en ajoutant une classe CSS
          this.$refs.animatedDivLeft.classList.add('animateLeft');
          this.$refs.animatedDivRight.classList.add('animateRight');  
          // this.$refs.animatedDiv.classList.add('animate');
        } else {
          // La div est sortie de la vue
          // Vous pouvez ici inverser l'animation, par exemple en supprimant la classe CSS
          this.$refs.animatedDivLeft.classList.remove('animateLeft');
          this.$refs.animatedDivRight.classList.remove('animateRight');
        }
      });
    },
    startObserving() {
    // Commencez à observer les éléments
    this.observer.observe(this.$refs.animatedDivLeft);
    this.observer.observe(this.$refs.animatedDivRight);
  },
  stopObserving() {
    // Arrêtez l'observateur IntersectionObserver
    if (this.observer) {
      this.observer.disconnect();
    }
  },
    getImageUrl(fileName) {
      return fileName;
    },
    // playVideo() {
    //   const videoPlayer = document.getElementById("video-player");
    //   if (videoPlayer) {
    //     videoPlayer.play();
    //   }
    // },
    async loadVideoInBackground() {
      if (this.isVideoReady) {
        await this.delay(3000);
        this.fadeInVideo();
      } else {
        while (!this.isVideoReady && this.timeElapsed < 3000) {
          await this.delay(100);
          this.timeElapsed += 100;
        }
        if (this.isVideoReady) {
          this.fadeInVideo();
        }
      }
    },
    fadeInVideo() {
      // Code pour effectuer un fade in sur la vidéo (ex: en utilisant les transitions CSS)
    },
    // delay(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // },
    async typeText(text) {
      for (let i = 0; i < text.length; i++) {
        this.currentText = text.substr(0, i + 1);
        await this.delay(this.fadeInDuration); // Fondu à l'écriture
      }
    },
    async eraseText(text) {
      for (let i = text.length; i > 0; i--) {
        this.currentText = text.substr(0, i - 1);
        await this.delay(this.fadeOutDuration); // Fondu à l'effacement
      }
    },
    async startTyping() {
      while (this.isTyping) {
        const currentText = this.texts[this.currentTextIndex];
        await this.typeText(currentText);
        await this.delay(1000); // Délai pour le texte affiché
        await this.eraseText(currentText);
        await this.delay(500); // Délai avant de réécrire le texte
        this.currentTextIndex = (this.currentTextIndex + 1) % this.texts.length;
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  }, 
    // setup() { 
    //     const videoContainer = ref(null);
    //     const videoPlayer = ref(null);
    //     const removeBackground = () => {
    //         videoContainer.value.style.backgroundImage = "none";
    //     } ;    
    //     return {
    //     videoContainer,
    //     videoPlayer,
    //     }
    // }, 
    // mounted() { 
    //     this.videoPlayer.addEventListener("play", this.removeBackground) }, 
    //     beforeUnmount() { this.videoPlayer.removeEventListener("play", this.removeBackground) } 
    setup() { 
        // Propriété réactive qui indique si la page est en chargement 
        const loading = ref(true);

        // Fonction qui met fin au chargement après un délai
        const endLoading = () => {
        // On met la propriété loading à false
        loading.value = false;
        };

        // Hook du cycle de vie qui s'exécute quand le composant est monté
        onMounted(() => {
        // On attend 2 secondes avant d'appeler la fonction endLoading
        setTimeout(endLoading, 3000);
        });

        // On expose la propriété loading
        return {
        loading,
        };
    }
};
</script>

<style>
/* Styles spécifiques à la page accueil si nécessaire */
 
.custom-slide {
    /* Styles pour personnaliser le titre */
    text-shadow: 2px 2px 4px #000; /* Ajoute une ombre au texte */
    color: white; /* Met le texte en blanc */
  transition: 0.7s ease-out; /* Transition pour le zoom et le fondu */
  }

  .custom-slide:hover {
    padding:20px;
    color: #fff;
    font-weight: 900;
    font-size: 28px; 
  transition: 0.2s ease-in; /* Transition pour le zoom et le fondu */
    text-shadow: 6px 4px 20px #000; /* Ajoute une ombre au texte */
    }

.glowing-text {
  font-size: 36px;
  /* color: #fff; Couleur du texte */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
  position: relative;
  overflow: hidden;
  /* width: 300px; /* Ajustez la largeur à votre besoin */
  /* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
  width: 100%;
  height: 100%;
  transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
  transform: scale(0.9); /* Augmente la taille de l'image à 120% */
  opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 100%;
  }
}

.background-image {
  background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation de sortie */
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
  animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
  animation: slideInLeft 2s ease both;
}

.slide-in-right {
  animation: slideInRight 2s ease both;
}

.slide-out-left {
  animation: slideOutLeft 2s ease both;
}

.slide-out-right {
  animation: slideOutRight 2s ease both;
}
.ma-div {
  /* Ajoutez votre contenu CSS existant pour la div ici */
  width: 200px;
  height: 200px;
  background-color: transparent;
  position: relative;
  border-radius: 4px; 
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
  height: 100%;
  background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
  height: 100%;
  background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}
.loader { 
     width: 100px; height: 100px; 
     margin: 0 auto;
     top: 500px;
      border: 10px solid #f3f3f3;
       border-top: 10px solid #3498db; 
       border-radius: 50%; 
        animation: spin 2s linear infinite; }

 @keyframes spin {  
    0% { transform: rotate(0deg); } 
     100% { transform: rotate(360deg); } }

.fade-enter-active, .fade-leave-active {
     transition: opacity 0.5s; }

.fade-enter-from, .fade-leave-to { opacity: 0; } 

.text-with-shadow {
  font-size: 18px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Ajoutez votre ombre personnalisée ici */
}

.half-border {
  position: relative;
  /* font-size: 32px; */
}

.half-border::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 20px;
  transform: translateX(-50%);
  width: 30px;
  top: 25px;
  border-bottom: 3px solid #1c4268; /* Couleur et épaisseur de la bordure */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.typicalWrapper::after {
  content: "|";
  animation: blink 1s infinite step-start;
  color: #a3a2a2;
}
.typicalWrapper {
  color: #fff;
}

@keyframes blink {
  50% { opacity: 0; }
}
/* Exemple 6: Animation d'image */
.image-fade-enter-active, .image-fade-leave-active {
  transition: opacity 0.5s;
}
.image-fade-enter, .image-fade-leave-to {
  opacity: 0;
}

.accueildiv {
/* Ajoutez votre contenu CSS existant pour la div ici */
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
transition: transform 1.5s;
}

.accueildiv:hover {
/* border: 1px solid #777676; */
transform: scale(1.07);
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.animated-div {
  transition: transform 0.5s;
}
.animateLeft {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveLeft 2s ease-in-out ;
}
.animateRight {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveRight 2s ease-in-out ;
}
@keyframes moveRight {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0); /* Déplace de 100 pixels vers la droite */
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0); /* Déplace de 100 pixels vers la droite */
  }
}
</style>






