import { createStore } from 'vuex';

const store = createStore({
  state: {
    // Votre état global ici
    videoReady: false,
    videoPath: './assets/video/video2.mp4',
    backgroundImage: './assets/img/tour/1000015534web.jpg',
  },
  mutations: {
    // Vos mutations pour modifier l'état global
    setVideoReady(state, value) {
      state.videoReady = value;
    },
    setVideoPath(state, path) {
      state.videoPath = path;
    },
  },
  actions: {
    // Vos actions pour effectuer des opérations asynchrones
    async loadVideoInBackground({ commit, state }) {
      const videoElement = document.createElement('video');
      videoElement.src = state.videoPath;
      videoElement.muted = true;
      videoElement.load();

      // Attendez que l'événement 'loadedmetadata' soit déclenché lorsque la vidéo est prête
      await new Promise(resolve => {
        videoElement.addEventListener('loadedmetadata', () => {
          commit('setVideoReady', true);
          resolve();
        });
      });

      // Mettez à jour l'état pour indiquer que la vidéo est prête
      // commit('setVideoReady', true);
    },
  },
  getters: {
    // Vos getters pour récupérer des données de l'état global
    isVideoReady: state => state.videoReady,
    backgroundImage: (state) => state.backgroundImage,
    videoPath: state => state.videoPath,
  },
  modules: {},
});

export default store;
