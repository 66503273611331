// main.js

import { createApp } from "vue";
import App from './App.vue';
import router from './router'; // importez le router depuis un autre fichier
import store from './store/store.js';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueSlickCarousel from 'vue-slick-carousel'; // importez le wrapper Slider carouresl depuis vue-slick-carousel
// import VueScrollfire from 'vue-scrollfire'
import VideoBackground from 'vue-responsive-video-background-player'

import 'vue-orgchart/dist/style.min.css'

// import swiper styles
import 'swiper/swiper-bundle.css'
// Importer vue-slick-carousel styles
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

const app = createApp(App) // créez l'instance de l'application
app.config.devtools = false 
app.use(router) // installez le plugin router
app.use(store)
app.component(VueAwesomeSwiper, VueAwesomeSwiper) // enregistrez le composant VueAwesomeSwiper
app.component(VueSlickCarousel, VueSlickCarousel) // enregistrez le composant VueSlickCarousel

app.component('video-background', VideoBackground);
// Utilisez Vue.use pour enregistrer VueScrollfire en tant que plugin global
// app.use(VueScrollfire);
app.mount("#app") // montez l'application sur l'élément #app