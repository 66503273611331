<template>
    <!-- ========== HEADER ========== -->
    <header id="header" class="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-light navbar-show-hide" data-hs-header-options='{
              "fixMoment": 1000,
              "fixEffect": "slide"
            }'>
  
      <div class="container">
        <nav class="js-mega-menu navbar-nav-wrap">
          <!-- Default Logo -->
          <router-link class="navbar-brand" to="/" aria-label="Front">
            <img style="width: 220px!important; height: 100px!important" class="card-transition" src="assets/img/logo.png" alt="Logo">
          </router-link>
          <!-- End Default Logo -->

          <!-- Toggler -->
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-default">
              <i class="bi-list"></i>
            </span>
            <span class="navbar-toggler-toggled">
              <i class="bi-x"></i>
            </span>
          </button>
          <!-- End Toggler -->

          <!-- Collapse -->
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <div class="navbar-absolute-top-scroller">
              <ul class="navbar-nav">
                
              <!-- Landings -->
              <li class="hs-has-sub-menu nav-item">
                <router-link
                  id="blogMegaMenu" style="color: #133985"
                  class="hs-mega-menu-invoker nav-link dropdown-toggle "
                  to="/quisommesnous"
                  role="button"
                  aria-expanded="false"
                  @mouseover="openSubMenu('blogMegaMenu')"
                >
                Qui sommes nous
                </router-link> 

                <!-- Mega Menu -->
                 <div class="hs-sub-menu dropdown-menu"
                  :class="{ show: isSubMenuOpen('blogMegaMenu') }"
                  aria-labelledby="blogMegaMenu"
                  style="min-width: 14rem;"
                  @mouseover="openSubMenu('blogMegaMenu')"
                  @mouseleave="closeSubMenu('blogMegaMenu')">

                  <router-link class="dropdown-item " to="/mission">Nos missions</router-link>
                  <div class="dropdown-divider"></div>
                  <router-link class="dropdown-item " to="/valeur">Nos valeurs</router-link>
                </div>
              </li>
              <!-- End Landings -->
              <!-- Company -->
              <!-- <li class="nav-item">
                <router-link id="companyMegaMenu" style="color: #133985" class="hs-mega-menu-invoker nav-link" to="/quisommesnous" role="button" aria-expanded="false">Qui sommes nous</router-link>
              </li> -->
              <!-- End Company -->
              
              <!-- Company -->
              <li class="nav-item">
                <router-link id="companyMegaMenu" style="color: #133985" class="hs-mega-menu-invoker nav-link" to="/gouvernance" role="button" aria-expanded="false">Gouvernance</router-link>
              </li>
                <!-- Company -->
                <li class="nav-item">
                  <router-link id="companyMegaMenu" style="color: #133985" class="hs-mega-menu-invoker nav-link active" to="/projet" role="button" aria-expanded="false">Notre portefeuille</router-link>
                </li>
                <!-- End Company -->
  
                <!-- Account -->
                <li class="nav-item">
                  <router-link id="accountMegaMenu" style="color: #133985" class="hs-mega-menu-invoker nav-link active" to="/actualite" role="button" aria-expanded="false">Actualités</router-link>
                </li>
                <!-- End Account -->
  
                <!-- Account -->
                <li class="nav-item">
                  <router-link id="accountMegaMenu" style="color: #133985" class="hs-mega-menu-invoker nav-link active" to="/contact" role="button" aria-expanded="false">Contactez-nous</router-link>
                </li>
                <!-- End Account -->
  
  
                <!-- Button -->
                <!-- End Button -->
              </ul>
            </div>
          </div>
          <!-- End Collapse -->
        </nav>
      </div>
    </header>
                
  
    <!-- ========== END HEADER ========== -->
  </template>
  
  <script>
  
  export default { 
    data() {
      return {
        openSubMenus: [] // Stocker les identifiants des sous-menus ouverts
      };
    },
      
    // Vous pouvez ajouter des propriétés et des méthodes spécifiques au header si nécessaire
    
      mounted() {
          // Liste des liens CSS à inclure
          const cssLinks = [
          'assets/css/vendor.min.css',
          'assets/vendor/bootstrap-icons/font/bootstrap-icons.css',
          'assets/vendor/aos/dist/aos.css',
          'assets/css/theme.minc619.css?v=1.0',
          // Ajoutez d'autres liens CSS ici
          ];
  
              // Créer les balises <link> pour les fichiers CSS
              cssLinks.forEach((link) => {
              const linkTag = document.createElement('link');
              linkTag.rel = 'stylesheet';
              linkTag.href = link;
              document.head.appendChild(linkTag);
              });
            },
    methods: {
      openSubMenu(id) {
        // Ouvrir le sous-menu lorsque la souris est pointée sur le menu
        if (!this.isSubMenuOpen(id)) {
          this.openSubMenus.push(id);
        }
      },
      closeSubMenu(id) {
        // Fermer le sous-menu lorsque la souris quitte le menu
        this.openSubMenus = this.openSubMenus.filter(menuId => menuId !== id);
      },
      isSubMenuOpen(id) {
        return this.openSubMenus.includes(id);
      }
    }
  };
  </script>
  
  <style>
  /* Styles spécifiques au header si nécessaire */
  
  </style>
  