<template>
    <div v-if="isLoading" class="loader-overlay">
      <div class="loader-container">
        <div class="circle-loader">
          <div class="circle"></div>
          <img src="assets/img/logo.png" alt="Logo" class="loading-logo" />
        </div>
      </div>
    </div>
    <router-view class="router-view" />
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    // Simulate a loading delay
    setTimeout(() => {
      this.isLoading = false; // Set isLoading to false when loading is done
    }, 2000); // 2 seconds
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.loader-overlay.visible {
  opacity: 0;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-loader {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3); */
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 16px solid transparent;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-logo {
  width: 300px;
  height: 130px;
  position: relative;
  z-index: 1;
}

.router-view {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s 2s;
}

.router-view.visible {
  opacity: 1;
  visibility: visible;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
