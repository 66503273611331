<template>
  <Header />

<!-- ========== MAIN CONTENT ========== -->
<main id="content" role="main" style="position: relative; top: 120px;">
  <!-- Hero -->
  <div class="bg-img-start" >
   <div :style="backgroundStyle" style="position: relative; width: 100%; height: 250px;">
      <div class="container content-space-t-3" >
      
        <div class="w-lg-75 text-center mx-lg-auto">
          <!-- Heading -->
          <div class="mb-5 mb-md-10">
            <h1 class="display-6 text-gray glowing-text" style="font-color: #fff !important">Nos missions</h1>
          </div>
          <!-- End Heading -->
        </div>
      </div>
    </div>
  <!-- End Gallery -->

  <div class="floating-balls" style="margin-top: 50px">
    <div class="ball" :style="{ backgroundColor: ball1.color, width: ball1.size + 'px', height: ball1.size + 'px', top: ball1.top + 'px', left: ball1.left + 'px' }"></div>
    <div class="ball" :style="{ backgroundColor: ball2.color, width: ball2.size + 'px', height: ball2.size + 'px', top: ball2.top + 'px', left: ball2.left + 'px' }"></div>
  </div>
            <!-- End Breadcrumb -->
        <!-- Info -->
        <div class="container">
            
          <nav class=" p-3" aria-label="breadcrumb" style="background: #133985; margin-top: 20px">
              <ol class="breadcrumb breadcrumb-light mb-0">
                <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/">Accueil  </router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Missions  </li>
              </ol>
            </nav>
          <div class="row justify-content-lg-between">
            <!-- <div class="col-md-12 card-transition h-100  ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
              <strong> <span style="color: #133985">MISSIONS</span>&nbsp;
                <span style="color: #000">DE LA SEDIC</span></strong>
            </div> -->
            <div class="col-lg-12" style="margin-top: 40px"> 
              <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                <span class="d-block small font-weight-bold text-cap mb-2">Les missions de la Sedic s'articulent autour des volets suivants :</span>
              </div>
             
              <div class="row" style="margin-top: -20px" >
                <div class="animated-div col-sm-6 mb-3 mb-sm-7 " ref="animatedDivLeft" >
                  <!-- Icon Blocks -->
                  <div class="d-flex missiondiv bg-dark" style="background-image: url(assets/svg/components/card-13.svg); color: #fff" >
                    <div class="flex-shrink-0">
                      <!-- <span>&mdash;</span> -->
                    </div>
                    <div class="flex-grow-1 ms-3"><br><br>
                      <p>Valoriser et pérenniser les infrastructures commerciales en assurant 
                      l'exploitation, la gestion et la maintenance de celle-ci</p>
                    </div>
                  </div>
                  <!-- End Icon Blocks -->
                </div>

                <div class="animated-div col-sm-6 mb-3 mb-sm-7" ref="animatedDivRight">
                  <!-- Icon Blocks -->
                  <div class="d-flex missiondiv bg-dark" style="background-image: url(assets/svg/components/card-9.svg); color: #fff">
                    <div class="flex-shrink-0">
                      <!-- <span>&mdash;</span> -->
                    </div>
                    <div class="flex-grow-1 ms-3"><br><br>
                      <p>Développer les infrastructures en assurant la construction, la 
                      rénovation et l'achèvement des ouvrages</p>
                    </div>
                  </div>
                  <!-- End Icon Blocks -->
                </div>  
                
                <div class="animated-div col-sm-6 mb-3 mb-sm-7 " ref="animatedDivLeft1">
                  <!-- Icon Blocks -->
                  <div class="d-flex missiondiv bg-dark" style="background-image: url(assets/svg/components/card-8.svg); color: #fff">
                    <div class="flex-shrink-0">
                      <!-- <span>&mdash;</span> -->
                    </div>
                    <div class="flex-grow-1 ms-3"><br><br>
                      <p>Participer au développement économique, social et environnemental du Congo</p>
                    </div>
                  </div>
                  <!-- End Icon Blocks -->
                </div>  

                <div class="animated-div col-sm-6 mb-3 mb-sm-7 " ref="animatedDivRight1">
                  <!-- Icon Blocks -->
                  <div class="d-flex missiondiv bg-dark" style="background-image: url(assets/svg/components/card-7.svg); color: #fff">
                    <div class="flex-shrink-0">
                      <!-- <span>&mdash;</span> -->
                    </div>
                    <div class="flex-grow-1 ms-3"><br><br>
                      <p>Participer au rayonnement du Congo à l'international en développant 
                      l'attractivité de la ville de Brazzaville à travers ces nouvelles
                       infrastructures modernes et innovantes</p>
                    </div>
                  </div>
                  <!-- End Icon Blocks -->
                </div>   
              </div> 

            </div>
          </div>
          <!-- End Row -->
        </div>
        
    <!-- Card Grid -->
    <div class="container " style="margin-top: -40px">

<div class="row">
        <div class="col-md-12 card-transition h-100  ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
          <strong> <span class="half-border" style="font-size: 17px; margin-bottom: 20px; color: #133985">FOC</span>
            <span style="color: #000">US</span></strong>
        </div>
  <!-- <span class="card-subtitle half-border"  style="font-size: 20px; font-weight: 900;color: #1c4268; margin-bottom: 20px">FOCUS</span> -->
  <div class="order-lg-0 col-sm-6 col-lg-4 mb-3 mb-sm-7">
    <!-- Card -->
    <router-link class="card card-stretched-vertical card-transition bg-img-start gradient-y-overlay-sm-gray-900" to="/focus1" style="background-image: url(assets/img/400x500/img4.jpg); min-height: 25rem;">
      <div class="card-header">
        <span class="card-subtitle text-white" style="padding: 10px; border-radius: 4px; 
          text-align: center; color: #fff; letter-spacing: 1px; background-color: rgba(0,0,0, 0.8);font-weight: 900">Expertise et Engagement</span>
      </div>

      <div class="card-footer">
        <h3 class="card-title text-white" >Expertise sectorielle approfondie</h3>
        <!-- <p class="card-link link-light">Voir plus <i class="bi-chevron-right small ms-1"></i></p> -->
      </div>
    </router-link>
    <!-- End Card -->
  </div>
  <!-- End Col -->

  <div class="order-lg-1 col-sm-6 col-lg-4 mb-3 mb-sm-7">
    <!-- Card -->
    <router-link class="card card-stretched-vertical card-transition bg-dark" to="/focus2" style="background-image: url(assets/svg/components/card-16.svg); min-height: 25rem;">
      <div class="card-header">
        <span class="card-subtitle text-white" style="padding: 10px; border-radius: 4px; 
          text-align: center; color: #fff; letter-spacing: 1px; background-color: rgba(0,0,0, 0.8);font-weight: 900">Impact Positif</span>
      </div>

      <div class="card-footer">
        <h3 class="card-title text-white" >Contribution à la modernisation des infrastructures essentielles</h3>
        <!-- <p class="card-link link-light">Voir plus <i class="bi-chevron-right small ms-1"></i></p> -->
      </div>
    </router-link>
    <!-- End Card -->
  </div>
  <!-- End Col -->

  <div class="order-lg-2 col-sm-6 col-lg-4 mb-3 mb-sm-7">
    <!-- Card -->
    <router-link class="card card-stretched-vertical card-transition bg-img-start gradient-y-overlay-sm-gray-900" to="/focus3" style="background-image: url(assets/img/400x500/img5.jpg); min-height: 25rem;">
      <div class="card-header">
        <span class="card-subtitle text-white" style="padding: 10px; border-radius: 4px; 
          text-align: center; color: #fff; letter-spacing: 1px; background-color: rgba(0,0,0, 0.8);font-weight: 900">Durabilité et Innovation</span>
      </div>

      <div class="card-footer">
        <h3 class="card-title text-white" >Gestion efficiente des ressources et des budgets</h3>
        <!-- <p class="card-link link-light">Voir plus <i class="bi-chevron-right small ms-1"></i></p> -->
      </div>
    </router-link>
    <!-- End Card -->
  </div>
  <!-- End Col -->

</div>
</div>
  <!-- Appliquez la classe d'animation de sortie vers le haut lorsque isExiting est true -->
      <p style="margin-top: 150px"></p>
        <!-- End Info -->
  <!-- End Hero -->
  </div>
</main>
  
  <Footer />
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'

export default {
name: 'mission-page',
data() {
  return {
    ball1: {
        color: 'rgba(210,228,181, 0.5)',
        size: 150,
        top: 100,
        left: 50,
      },
      ball2: {
        color: 'rgba(45,97,124, 0.5)',
        size: 80,
        top: 200,
        left: 150,
      },
    isVisible: false, // Initialisez à false pour masquer l'image initialement
    isExiting: false,
    backgroundImages: [
      'url(assets/img/tour/tourju.jpg)',
        'url(assets/img/tour/tour5.jpg)',
        'url(assets/img/slider/banni3.jpg)',
    ],
    observer: null,
    currentIndex: 0,
    animationDuration: 4000, // Durée de l'animation en millisecondes
  };
},
computed: {
  backgroundStyle() {
    return {
      backgroundImage: this.backgroundImages[this.currentIndex],
      animation: `scrollBackground ${this.animationDuration / 400}s linear infinite`,
      backgroundSize: 'cover', // Mettre l'image en mode "cover"
    };
  },
},
methods: {
  
    // Cette fonction sera appelée lorsque la div entre ou sort de la vue
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // La div est entrée dans la vue
          // Vous pouvez ici déclencher une animation, par exemple en ajoutant une classe CSS
          this.$refs.animatedDivLeft.classList.add('animateLeft');
          this.$refs.animatedDivRight.classList.add('animateRight');
          this.$refs.animatedDivLeft1.classList.add('animateLeft1');
          this.$refs.animatedDivRight1.classList.add('animateRight1');
          // this.$refs.animatedDiv.classList.add('animate');
        } else {
          // La div est sortie de la vue
          // Vous pouvez ici inverser l'animation, par exemple en supprimant la classe CSS
          this.$refs.animatedDivLeft.classList.remove('animateLeft');
          this.$refs.animatedDivRight.classList.remove('animateRight');
          this.$refs.animatedDivLeft1.classList.remove('animateLeft1');
          this.$refs.animatedDivRight1.classList.remove('animateRight1');
          // this.$refs.animatedDiv.classList.remove('animate');
        }
      });
    },
    startObserving() {
    // Commencez à observer les éléments
    this.observer.observe(this.$refs.animatedDivLeft);
    this.observer.observe(this.$refs.animatedDivRight);
    this.observer.observe(this.$refs.animatedDivLeft1);
    this.observer.observe(this.$refs.animatedDivRight1);
  },
  stopObserving() {
    // Arrêtez l'observateur IntersectionObserver
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  changeItem() {
      const randomIndex = Math.floor(Math.random() * this.items.length);
      this.currentItem = this.items[randomIndex];
    },
  startBackgroundScroll() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
    }, this.animationDuration);
  },
  changeBackgroundImage() {
    this.fadingOut = true;
    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
      this.fadingOut = false;
    }, 1000);
  },
checkIfVisible() {
  const element = document.querySelector('.mg');
  if (!element) return;

  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // Vérifiez si l'élément est visible à l'écran
  if (rect.top <= windowHeight && rect.bottom >= 100) {
    this.isVisible = true;
  } else {
    this.isVisible = false; // Assurez-vous de définir isVisible à false lorsque l'élément n'est pas visible

    // Si l'utilisateur quitte le pied de page en remontant, appliquez l'animation de sortie vers le haut
    if (window.scrollY < rect.top) {
      this.isExiting = true;
    } else {
      this.isExiting = false;
    }
  }
},
},
mounted() {
    // Créez une instance de l'IntersectionObserver
    const options = {
      root: null, // Utilise la fenêtre comme zone d'observation
      rootMargin: '0px', // Aucune marge supplémentaire
      threshold: 0.5, // 0.5 signifie que 50% de la div doit être visible pour déclencher l'animation
    };

    // const observer = new IntersectionObserver(this.handleIntersection, options);
    this.observer = new IntersectionObserver(this.handleIntersection, options);
    // Commencez à observer les éléments lorsque le composant est monté
    this.startObserving();

    // Commencez à observer la div
    // observer.observe(this.$refs.animatedDiv);
    // observer.observe(this.$refs.animatedDivLeft);
    // observer.observe(this.$refs.animatedDivRight);
    // observer.observe(this.$refs.animatedDivLeft1);
    // observer.observe(this.$refs.animatedDivRight1);
  this.startBackgroundScroll();
  // Écoutez l'événement de défilement pour vérifier si l'élément est visible
  window.addEventListener('scroll', this.checkIfVisible);
  // Vérifiez une première fois au chargement de la page
  this.checkIfVisible();
},
beforeUnmount() {
  // Assurez-vous de supprimer l'écouteur d'événement lorsque le composant est détruit
  window.removeEventListener('scroll', this.checkIfVisible);
  // Arrêtez l'observation lorsque vous quittez le composant
  this.stopObserving();
},
components: {
  Header,
  Footer
}
  
}
</script>

<style>
/* Styles spécifiques à la page si nécessaire */

.floating-balls {
  position: absolute;
  width: 100%;
  height: 400px;
}

.ball {
  position: relative;
  border-radius: 50%;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
.glowing-text {
font-size: 36px;
/* color: #fff; Couleur du texte */
text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
position: relative;
overflow: hidden;
/* width: 300px; /* Ajustez la largeur à votre besoin */
/* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
width: 100%;
height: 100%;
transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
transform: scale(0.9); /* Augmente la taille de l'image à 120% */
opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
0% {
  background-position: center 0;
}
100% {
  background-position: center 100%;
}
}

.background-image {
background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
from {
  transform: translateX(-100%);
  opacity: 0;
}
to {
  transform: translateX(0);
  opacity: 1;
}
}

@keyframes slideInRight {
from {
  transform: translateX(100%);
  opacity: 0;
}
to {
  transform: translateX(0);
  opacity: 1;
}
}

/* Animation de sortie */
@keyframes slideOutLeft {
from {
  transform: translateX(0);
  opacity: 1;
}
to {
  transform: translateX(-100%);
  opacity: 0;
}
}

@keyframes slideOutRight {
from {
  transform: translateX(0);
  opacity: 1;
}
to {
  transform: translateX(100%);
  opacity: 0;
}
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
from {
  transform: translateY(0);
  opacity: 1;
}
to {
  transform: translateY(-100%);
  opacity: 0;
}
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
animation: slideInLeft 2s ease both;
}

.slide-in-right {
animation: slideInRight 2s ease both;
}

.slide-out-left {
animation: slideOutLeft 2s ease both;
}

.slide-out-right {
animation: slideOutRight 2s ease both;
}

.ma-div {
/* Ajoutez votre contenu CSS existant pour la div ici */
width: 200px;
height: 200px;
background-color: transparent;
position: relative;
border-radius: 4px; 
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
height: 100%;
background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
content: "";
position: absolute;
top: 0;
right: 0;
width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
height: 100%;
background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.missiondiv {
/* Ajoutez votre contenu CSS existant pour la div ici */
/* background-color: transparent; */
background-color: rgba(201, 191, 240, 0.5) !important;
position: relative;
height: 150px;
padding-top: 20px;
padding: 20px;
text-align: justify;
color: #474646;
border-radius: 4px; 
border: 1px solid #eee;
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
transition: transform 1.5s;
}

.missiondiv:hover {
/* border: 1px solid #777676; */
transform: scale(1.07);
box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}
.animated-div {
  transition: transform 0.5s;
}

.animate {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveRight 2s ease-in-out ;
}
.animateLeft {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveLeft 2s ease-in-out ;
}
.animateRight {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveRight 2s ease-in-out ;
}
.animateLeft1 {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveLeft 2s ease-in-out ;
}
.animateRight1 {
  /* transform: scale(1.2); Par exemple, faites un zoom sur la div */
  animation: moveRight 2s ease-in-out ;
}
@keyframes moveRight {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0); /* Déplace de 100 pixels vers la droite */
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0); /* Déplace de 100 pixels vers la droite */
  }
}

.element {
  animation: moveRight 2s ease-in-out infinite;
}
</style>
