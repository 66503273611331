<template>
  
  <!-- ========== FOOTER ========== -->
  <footer style="background-color:#133985; margin-top: 20px ">
    <div class="container pb-1 pb-lg-5">
      <div class="row">
        <div class="col-lg-4" style="text-align:center">
          <img class="card-transition" style="width: 105px; height: 105px; position: relative; top: 20px" src="assets/img/armoirie.png" alt="">
    
            <!-- Socials -->
            <ul class="list-inline mb-0" style="position: relative; margin-top: 25px">
              <li class="list-inline-item">
                <a class="btn btn-soft-light btn-xs btn-icon" href="#">
                  <i class="bi-facebook"></i>
                </a>
              </li>

              <li class="list-inline-item">
                <a class="btn btn-soft-light btn-xs btn-icon" href="#">
                  <i class="bi-linkedin"></i>
                </a>
              </li>

              <li class="list-inline-item">
                <a class="btn btn-soft-light btn-xs btn-icon" href="#">
                  <i class="bi-youtube"></i>
                </a>
              </li>

              <!-- <li class="list-inline-item">
                <div class="btn-group">
                  <button type="button" class="btn btn-soft-light btn-xs dropdown-toggle" id="footerSelectLanguage" data-bs-toggle="dropdown" aria-expanded="false" data-bs-dropdown-animation>
                    <span class="d-flex align-items-center">
                      <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/fr.svg" alt="Image description" width="16" />
                      <span>Français</span>
                    </span>
                  </button>

                  <div class="dropdown-menu" aria-labelledby="footerSelectLanguage">
                    <a class="dropdown-item d-flex align-items-center active" href="#">
                      <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/fr.svg" alt="Image description" width="16" />
                      <span>Français</span>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                      <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/us.svg" alt="Image description" width="16" />
                      <span>Anglais</span>
                    </a>
                  </div>
                </div>
              </li> -->
            </ul>
            <!-- End Socials -->
        </div>
        <div class="col-lg-4" style="text-align:center">
          <img class="card-transition" style="width: 295px!important; height: 130px!important; margin-top: 10px"  src="assets/img/logoblanc.png" alt="Logo">
          <!-- <div class="border-top border-white-10 "></div> -->  
          <p class="text-white-50 small" style="text-align: right">&copy; 2023 SEDIC. Tous droits réservés. Réalisé par <a href="https://astraas.com" target="_blank"> Astraas</a></p>
        </div>
        <div class="col-lg-4" style="text-align:center">
          <img class="card-transition" style="position: relative; width: 170px; height: 170px; top: 0px" src="assets/img/grandstravauxv1.png" alt="">
        </div>
      </div>
      

    </div>
  </footer>

  <!-- ========== END FOOTER ========== -->
  
  <!-- Go To -->
    
    <a class="js-go-to go-to position-fixed" href="javascript:;" :class="{
        'animated hs-go-to-prevent-event fadeInUp': showScrollToTopButton,
      }" @click.prevent="scrollToTop"
      :style="{  right: '2rem', bottom: '2rem', visibility: showScrollToTopButton ? 'visible' : 'hidden' }"
      :data-hs-go-to-options="JSON.stringify(goToOptions)"
    >
      <i class="bi-chevron-up"></i>
    </a>
  <!-- Page Preloader -->
  <div v-if="showPreloader" id="jsPreloader" class="page-preloader">
      <div class="page-preloader-middle gap-2">
        <span class="spinner-grow text-primary" role="status" aria-hidden="true"></span>
        <span>Chargement...</span>
      </div>
    </div>
</template>

<script>
 
export default {
  data() { 
    return { 
      // Un booléen pour indiquer si le scrollup est visible ou non 
      // showScrollUp: false,
      showPreloader: true,
      goToOptions: {
        offsetTop: 100,
      },
      showScrollToTopButton: false,
    }; 
  },
  mounted() {
    this.hidePreloader(); 
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: { 
    hidePreloader() {
      setTimeout(() => {
        this.showPreloader = false;
      }, 200);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showScrollToTopButton = window.scrollY > this.goToOptions.offsetTop;
    },
  },
  beforeMount() {
    // Liste des liens vers les fichiers JavaScript à inclure dans le footer
    const jsFiles = [
      'assets/js/vendor.min.js',
      'assets/vendor/aos/dist/aos.js',
      // 'assets/js/theme.min.js',
      // Ajoutez d'autres liens vers les fichiers JavaScript ici
    ];

    // Créer les balises <script> pour les fichiers JavaScript
    jsFiles.forEach((jsFile) => {
      const scriptTag = document.createElement('script');
      scriptTag.src = jsFile;
      document.body.appendChild(scriptTag); // Ajouter les scripts à la fin du body
    });
  },
};
</script>

<style>
/* Styles spécifiques au footer si nécessaire */ 
</style>