<template>
    <Header />

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" role="main" style="position: relative; top: 120px; margin-bottom: 120px;">
    <!-- Hero -->
    <div class="bg-img-start" style="background-image: url(assets/svg/components/card-11.svg);">
    <!-- <div class="bg-img-start" style="background-image: url(assets/img/silhou.jpg);"> -->
      <div :style="backgroundStyle" style="position: relative; width: 100%; height: 250px;
       ">
        <div class="container content-space-t-3" >
        
          <div class="w-lg-75 text-center mx-lg-auto">
            <!-- Heading -->
            <div class="mb-5 mb-md-10">
              <h1 class="display-6 text-gray glowing-text" style="font-color: #fff !important">Gouvernance</h1>
            </div>
            <!-- End Heading -->
          </div>
        </div>
      </div>

    <!-- Team -->
    <div class="container ">
      <!-- Heading -->
      <!-- <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <h2>Membres du Conseil d'administration</h2>
      </div> -->
      <!-- End Heading -->
      <nav class=" p-3" aria-label="breadcrumb" style="background: #133985; margin-top: 20px">
          <ol class="breadcrumb breadcrumb-light mb-0">
            <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/">Accueil  </router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Gouvernance  </li>
          </ol>
        </nav>

      <div class="row gx-3 mb-5"> 
              <div class="col-md-12 card-transition h-100 ma-div" style="background-color: #fff; margin-top: 20px;margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
                <strong> <span style="color: #133985">MEMBRES</span>&nbsp;
                  <span style="color: #000">DU CONSEIL D'ADMINISTRATION</span></strong>
              </div>
        <div class="col-sm-6 col-lg-3 mb-3">
          <!-- Card -->
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/myriam.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Directrice Générale</span>
              <h4 class="card-title">Bénédicte Myriam DENGUET-ATTICKY</h4>
             </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
        <div class="col-sm-6 col-lg-3 mb-3">
          <!-- Card -->
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/160x160/img1.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Administration Générale & Affaires Juridiques</span>
              <!-- <h4 class="card-title">Prenom Nom</h4> -->
             </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
        <div class="col-sm-6 col-lg-3 mb-3">
          <!-- Card -->
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/160x160/img1.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Développement et acquisitions </span>
              <!-- <h4 class="card-title">Prenom Nom</h4> -->
             </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
        <div class="col-sm-6 col-lg-3 mb-3">
          <!-- Card -->
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/160x160/img1.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Ressources Humaines et Synergies</span>
              <!-- <h4 class="card-title">Prenom Nom</h4> -->
             </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->



      </div>
      <!-- End Row -->
      <hr>
      <!-- <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <h2>Organigramme de la SEDIC</h2>
      </div> -->
      
      <div class="row gx-3 mb-5">
              <div class="col-md-12 card-transition h-100 ma-div" style="background-color: #fff; margin-top: 20px; margin-bottom: 20px; padding-top: 25px; padding-bottom: 25px">
                <strong> <span style="color: #133985">ORGANIGRAMME</span>&nbsp;
                  <span style="color: #000">DE LA SEDIC</span></strong>
              </div>
        <div class="col-sm-6 col-lg-3 mb-3">
          <!-- Card -->
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/myriam.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Directrice générale</span>
              <h4 class="card-title">Bénédicte Myriam DENGUET-ATTICKY</h4>
             </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
        
      </div>
      <div class="row gx-3 mb-5">
        <div class="col-sm-6 col-lg-3 mb-3">
          <!-- Card -->
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/bureau/photo5.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Directeur Financier & Comptable</span>
              <h4 class="card-title">Salissou Mohamed MVONDO</h4>
             </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
        <div class="col-sm-6 col-lg-3 mb-3">
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/160x160/img1.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Chargée de Communication - Marketing</span>
              <h4 class="card-title">Kennie KINSANGOU</h4>
             </div>
          </div>
        </div>
        
        <div class="col-sm-6 col-lg-3 mb-3">
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/bureau/photo1.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Directrice Administration & Affaires Juridiques</span>
              <h4 class="card-title">Aïmeliah DEBEKA NDOUO</h4>
             </div>
          </div>
        </div>
        
      </div>
      <div class="row gx-3 mb-5">
        <div class="col-sm-6 col-lg-3 mb-3">
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/bureau/photo2.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Titre du Poste</span>
              <!-- <h4 class="card-title">Prenom Nom</h4> -->
             </div>
          </div>
        </div>
        
        <div class="col-sm-6 col-lg-3 mb-3">
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/bureau/photo3.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Titre du Poste</span>
              <!-- <h4 class="card-title">Prenom Nom</h4> -->
             </div>
          </div>
        </div>
        
        <div class="col-sm-6 col-lg-3 mb-3">
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/bureau/photo4.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Titre du Poste</span>
              <!-- <h4 class="card-title">Prenom Nom</h4> -->
             </div>
          </div>
        </div>
        
        <div class="col-sm-6 col-lg-3 mb-3">
          <div class="card card-transition h-100">
            <div class="card-body">
              <div class="avatar  avatar-circle mb-4" style="height:210px">
                <img style="width: 200px; height: 200px; border-radius: 50%" class="" src="assets/img/160x160/img1.jpg" alt="Image Description">
              </div>

              <span class="card-subtitle">Titre du Poste</span>
              <!-- <h4 class="card-title">Prenom Nom</h4> -->
             </div>
          </div>
        </div>



      </div>
     
      <!-- <div class="container">
      <div class="level">
        <div class="node dg">DG</div>
      </div>
      <div class="level">
        <div class="node director">Directeur 1</div>
        <div class="node director">Directeur 2</div>
        <div class="node director">Directeur 3</div>
      </div>
      <svg height="100" width="500">
        <line x1="150" y1="0" x2="50" y2="100" style="stroke:black;stroke-width:2" />
        <line x1="250" y1="0" x2="150" y2="100" style="stroke:black;stroke-width:2" />
        <line x1="350" y1="0" x2="250" y2="100" style="stroke:black;stroke-width:2" />
        <line x1="50" y1="100" x2="50" y2="200" style="stroke:black;stroke-width:2" />
        <line x1="150" y1="100" x2="150" y2="200" style="stroke:black;stroke-width:2" />
        <line x1="250" y1="100" x2="250" y2="200" style="stroke:black;stroke-width:2" />
      </svg>
      <div class="level">
        <div class="node employee">Employé 1</div>
        <div class="node employee">Employé 2</div>
        <div class="node employee">Employé 3</div>
        <div class="node employee">Employé 4</div>
        <div class="node employee">Employé 5</div>
        <div class="node employee">Employé 6</div>
      </div>
    </div> -->
    
    </div>
    <!-- End Team -->
    </div>
    <!-- End Hero -->
  </main>
    
    <Footer />
</template>

<script>
import Header from '../components/HeaderPage.vue'
import Footer from '../components/FooterLayout.vue'


export default {
  name: 'gouvernance-page',
  data() {
    return {
      isVisible: false, // Initialisez à false pour masquer l'image initialement
      isExiting: false,
      backgroundImages: [
        'url(assets/img/city10.jpg)',
        'url(assets/img/city9.jpg)', // Remplacez ceci par les URL de vos images
      ],
      currentIndex: 0,
      animationDuration: 4000, // Durée de l'animation en millisecondes
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: this.backgroundImages[this.currentIndex],
        animation: `scrollBackground ${this.animationDuration / 400}s linear infinite`,
        backgroundSize: 'cover', // Mettre l'image en mode "cover"
      };
    },
  },
  methods: {
    startBackgroundScroll() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
      }, this.animationDuration);
    },
    changeBackgroundImage() {
      this.fadingOut = true;
      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
        this.fadingOut = false;
      }, 1000);
    },
  checkIfVisible() {
    const element = document.querySelector('.mg');
    if (!element) return;

    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Vérifiez si l'élément est visible à l'écran
    if (rect.top <= windowHeight && rect.bottom >= 100) {
      this.isVisible = true;
    } else {
      this.isVisible = false; // Assurez-vous de définir isVisible à false lorsque l'élément n'est pas visible

      // Si l'utilisateur quitte le pied de page en remontant, appliquez l'animation de sortie vers le haut
      if (window.scrollY < rect.top) {
        this.isExiting = true;
      } else {
        this.isExiting = false;
      }
    }
  },
  },
  mounted() {
    this.startBackgroundScroll();
    // Écoutez l'événement de défilement pour vérifier si l'élément est visible
    window.addEventListener('scroll', this.checkIfVisible);
    // Vérifiez une première fois au chargement de la page
    this.checkIfVisible();
  },
  beforeUnmount() {
    // Assurez-vous de supprimer l'écouteur d'événement lorsque le composant est détruit
    window.removeEventListener('scroll', this.checkIfVisible);
  },
  components: {
    Header,
    Footer,  
  }
};
</script>

<style>
/* Styles spécifiques à la page si nécessaire */

.org-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.org-node {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.org-node .line {
  width: 2px;
  background-color: #000;
  flex-grow: 1;
}

.org-node .name {
  background-color: lightblue;
  width: 100px;
  text-align: center;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 5px;
}
/* .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.node {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  border: 1px solid black;
  margin: 5px;
}

.dg {
  background-color: lightblue;
}

.director {
  background-color: lightgreen;
}

.employee {
  background-color: lightyellow;
} */

.glowing-text {
  font-size: 36px;
  /* color: #fff; Couleur du texte */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Ombre lumineuse */
}

.image-zoom-container {
  position: relative;
  overflow: hidden;
  /* width: 300px; /* Ajustez la largeur à votre besoin */
  /* height: 200px; Ajustez la hauteur à votre besoin */ 
}

.image-zoom {
  width: 100%;
  height: 100%;
  transition: transform 1.5s, opacity 1.5s; /* Transition pour le zoom et le fondu */
}

.image-zoom-container:hover .image-zoom {
  transform: scale(0.9); /* Augmente la taille de l'image à 120% */
  opacity: 0.7; /* Réduit légèrement l'opacité pour l'effet de fondu */
}
@keyframes scrollBackground {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 100%;
  }
}

.background-image {
  background-size: cover;
}
/* Animation d'entrée */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation de sortie */
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
/* Animation de sortie vers le haut */
@keyframes slideOutUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Appliquer l'animation de sortie vers le haut */
.slide-out-up {
  animation: slideOutUp 2s ease both;
}

/* Appliquer les animations d'entrée et de sortie aux éléments */
.slide-in-left {
  animation: slideInLeft 2s ease both;
}

.slide-in-right {
  animation: slideInRight 2s ease both;
}

.slide-out-left {
  animation: slideOutLeft 2s ease both;
}

.slide-out-right {
  animation: slideOutRight 2s ease both;
}
.ma-div {
  /* Ajoutez votre contenu CSS existant pour la div ici */
  width: 200px;
  height: 200px;
  background-color: transparent;
  position: relative;
  border-radius: 4px; 
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1); 
}

.ma-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à gauche */
  height: 100%;
  background: linear-gradient(to right, #f9fafc, white); /* Dégradé de gauche à droite de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}

.ma-div::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; /* La moitié de la largeur de la div pour l'ombre à droite */
  height: 100%;
  background: linear-gradient(to left, #f9fafc, white); /* Dégradé de droite à gauche de transparent à blanc */
  z-index: -1; /* Placez l'ombre derrière le contenu de la div */
}
</style>
